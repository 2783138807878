import { useEffect, useState } from 'react';
import { Editor as TinyMCEEditor, EditorOptions, EditorEvent } from 'tinymce';
import { Link, PreviewLink } from '@setvi/shared/interfaces';
import {
  getPreviewLink,
  normalizeLinkToPreviewLink
} from '@setvi/shared/utils';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';

import {
  SEditorType,
  IEditorHook,
  IHandleLinkOptions,
  PastePostProcessEvent
} from '../../interfaces';
import { viewIcon, editIcon, removeIcon } from '../../components';

export enum LinkActions {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  REMOVE = 'remove',
  INSERT = 'insert',
  UPDATE = 'update'
}

export const useEditorLinksHook = ({
  editorRef,
  importedLinks,
  getLinkAction,
  getLinks
}: IEditorHook) => {
  const [links, setLinks] = useState<Link[]>(importedLinks || []);
  const [currentId, setCurrentId] = useState(null);

  // We have a lot links with old logic so this is backup to recognize old links and replace them with new ones
  useEffect(() => {
    if (editorRef && importedLinks?.length) {
      const content = editorRef?.getBody();

      const oldLinks = content?.querySelectorAll('a[href].immutableLink');

      if (oldLinks?.length) {
        oldLinks.forEach((aTag: HTMLElement) => {
          const currentLink = importedLinks?.find(
            elem =>
              elem?.Placeholder === aTag.dataset.id ||
              elem?.Placeholder === aTag.getAttribute('href')
          );

          const normalizedLink = normalizeLinkToPreviewLink(currentLink);
          const href = generateLinkViewerUrl(normalizedLink);

          const placeholder = currentLink?.Placeholder;

          aTag.setAttribute('id', placeholder); // Change 'newIdValue' to the new id value
          aTag.setAttribute('href', href); // Change 'newHrefValue' to the new href value
          aTag.classList.remove('immutableLink'); // Add a new class or modify existing classes
        });
      }

      const newImportedLinks: Link[] = [];

      importedLinks.forEach(link => {
        if (links?.includes(link)) return;

        const normalizedLink = normalizeLinkToPreviewLink(link);
        const previewHref = generateLinkViewerUrl(
          normalizedLink as unknown as PreviewLink
        );

        editorRef?.execCommand(
          'mceInsertContent',
          false,
          ` <a id=${link?.Placeholder} href=${link?.Placeholder} target="_blank" rel="noreferrer noopener" data-preview-href=${previewHref} >${link?.Name}</a> `
        );

        newImportedLinks.push(link);
      });

      setLinks(prev => [...prev, ...newImportedLinks]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, importedLinks]);

  useEffect(() => {
    if (currentId) {
      setLinks(prev => prev.filter(link => currentId !== link.Placeholder));
      getLinks?.(links.filter(link => currentId !== link.Placeholder));
      setCurrentId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  const editLink = (editorNode: TinyMCEEditor) => {
    const selection = editorRef?.selection || editorNode?.selection;
    const linkNode = selection?.getNode();
    const Id = linkNode?.id;

    getLinkAction?.({
      action: LinkActions.EDIT,
      id: Id
    });
  };

  const deleteLink = (
    editorNode: TinyMCEEditor,
    preventDefault?: () => void
  ) => {
    const editor = editorRef || editorNode;
    const selection = editor?.selection;
    const selectedNode = selection?.getNode();

    const highlitedContent = selection?.getContent({ format: 'html' });

    if (highlitedContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = highlitedContent;
      const aElements = tempDiv.querySelectorAll('a[href]');

      const deletedLinks: string[] = [];
      aElements.forEach(a => {
        deletedLinks.push(a?.id);
      });

      setLinks(prev =>
        prev.filter(link => !deletedLinks?.some(a => a === link.Placeholder))
      );
    }

    if (selectedNode?.tagName === 'A') {
      // If the selected node is an <a> tag, delete only the selected node
      const range = selection.getRng();
      range.setStartBefore(selectedNode);
      range.setEndAfter(selectedNode);
      selection.setRng(range);

      setCurrentId(selectedNode?.id);

      editor.execCommand('Delete');
      setLinks(prev =>
        prev.filter(link => selectedNode?.id !== link.Placeholder)
      );

      preventDefault?.();
    }
  };

  const viewLink = (editorNode: TinyMCEEditor) => {
    const selection = editorRef?.selection || editorNode?.selection;

    const linkNode = selection?.getNode();

    const link =
      linkNode?.dataset?.previewHref || linkNode?.getAttribute('href');

    window.open(link, '_blank').focus();
  };

  const handleCursorIsInsideLink = (editor: SEditorType) => {
    const bookmark = editor.selection.getBookmark();
    editor.selection.moveToBookmark(bookmark);

    // Check if the cursor is inside a link
    const node = editor.selection.getNode();

    if (node.nodeName === 'A' && node?.hasAttribute('id')) {
      // Move the cursor to the end of the link
      const range = editor.selection.getRng();
      range.setEndAfter(node);
      range.collapse(false);
      editor.selection.setRng(range);
      editor.execCommand('mceInsertContent', false, ' ');
    }
  };

  // Remove whole link for backspace and delete key
  const handleKeyDown = async (
    e: {
      keyCode: number;
      preventDefault: () => void;
    },
    editorNode: TinyMCEEditor
  ) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      // Check if the backspace (8) or delete (46) key is pressed
      await deleteLink(editorNode, e.preventDefault);
    } else {
      const ignoredKeyCodes = [37, 38, 39, 40]; //   Arrow keys
      if (ignoredKeyCodes.includes(e.keyCode)) return;
      handleCursorIsInsideLink(editorNode);
    }
  };

  const insertLink = (link: Link) => {
    if (!link || !editorRef) return;

    const previewHref = generateLinkViewerUrl(getPreviewLink(link));

    editorRef?.execCommand(
      'mceInsertContent',
      false,
      ` <a id=${link?.Placeholder} href=${link?.Placeholder} target="_blank" rel="noreferrer noopener" data-preview-href=${previewHref}>${link?.Name}</a> `
    );

    setLinks(prev => [...prev, link]);
    if (getLinks) getLinks([...links, link]);
  };

  const updateLink = (link: Link, oldLink: Link) => {
    const node = editorRef?.dom
      .getRoot()
      .querySelector(`[id="${oldLink.Placeholder}"]`);

    if (!link || !editorRef || !node) return;

    const href = generateLinkViewerUrl(getPreviewLink(link));

    node.textContent = link?.Name;
    node.setAttribute('href', link?.Placeholder);
    node.setAttribute('id', link?.Placeholder);
    node.setAttribute('data-preview-href', href);

    const updatedLinks = links.map(prevLink =>
      prevLink?.Placeholder === oldLink?.Placeholder ? link : prevLink
    );
    setLinks(updatedLinks);

    getLinkAction?.({
      action: LinkActions.UPDATE,
      id: link?.Placeholder
    });

    getLinks?.(updatedLinks);
    editorRef?.execCommand('mceInsertContent', false, '');
  };

  const removeLink = (removedLink: Link) => {
    const node = editorRef?.dom
      .getRoot()
      .querySelector(`[id="${removedLink.Placeholder}"]`);

    if (!deleteLink || !editorRef || !node) return;

    node.remove();
    const newLinks =
      links.filter(link => removedLink.Placeholder !== link.Placeholder) || [];
    setLinks(newLinks);
    getLinks?.(newLinks);
  };

  const importPastedLinks = (e: EditorEvent<PastePostProcessEvent>) => {
    const pastedNode = e.node;
    const aTags = pastedNode.getElementsByTagName('A');

    if (aTags.length > 0) {
      for (let i = 0; i < aTags.length; i += 1) {
        if (aTags[i]?.getAttribute('id')) {
          aTags[i].removeAttribute('id');
        }
      }
    }
  };

  const handleSnippet = (value: string) => {
    if (!editorRef) return;

    editorRef?.execCommand('mceInsertContent', false, value);
  };

  const handleLinks = (
    action: LinkActions,
    { link, oldLink, editorNode }: IHandleLinkOptions
  ) => {
    switch (action) {
      case LinkActions.EDIT:
        editLink(editorNode);
        break;
      case LinkActions.DELETE:
        deleteLink(editorNode);
        break;
      case LinkActions.REMOVE:
        removeLink(link);
        break;
      case LinkActions.INSERT:
        insertLink(link);
        break;
      case LinkActions.UPDATE:
        updateLink(link, oldLink);
        break;
      default:
        viewLink(editorNode);
    }
  };

  const initLinkOptions: Partial<Omit<EditorOptions, 'selector' | 'target'>> = {
    setup: (editor: TinyMCEEditor) => {
      editor.on('keydown', e => {
        handleKeyDown(e, editor);
      });

      editor.on('PastePostProcess', e => {
        importPastedLinks(e);
      });

      editor.ui.registry.addContextToolbar('firstContextToolbar', {
        predicate: node => node.nodeName === 'A' && !node?.hasAttribute('id'),
        items: 'viewlink',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addContextToolbar('secondContextToolbar', {
        predicate: node => node.nodeName === 'A' && node?.hasAttribute('id'),
        items: 'viewlink editlink deletelink',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addIcon('view', viewIcon);
      editor.ui.registry.addIcon('edit', editIcon);
      editor.ui.registry.addIcon('trash', removeIcon);

      editor.ui.registry.addButton('viewlink', {
        icon: 'view',
        text: 'View',
        tooltip: 'View Link',
        onAction: () =>
          handleLinks(LinkActions.VIEW, {
            editorNode: editor
          })
      });

      editor.ui.registry.addButton('editlink', {
        icon: 'edit',
        text: 'Edit',
        tooltip: 'Edit Link',
        onAction: () =>
          handleLinks(LinkActions.EDIT, {
            editorNode: editor
          })
      });

      editor.ui.registry.addButton('deletelink', {
        icon: 'trash',
        text: 'Delete',
        tooltip: 'Delete Link',
        onAction: () =>
          handleLinks(LinkActions.DELETE, {
            editorNode: editor
          })
      });
    }
  };

  return {
    links,
    currentId,
    initLinkOptions,

    setLinks,
    handleLinks,
    setCurrentId,
    handleKeyDown,
    handleSnippet,
    importPastedLinks
  };
};
