import { Box, Divider, Button } from '@material-ui/core';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Link, Resource } from '@setvi/shared/interfaces';
import { SSearchInput as SearchInput } from '@setvi/shared/components/sforms/ssearch';
import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { HomeIcon } from '@setvi/shared/components/sicons';
import { RESOURCE_TYPE } from '@setvi/shared/enums';
import { Breadcrumbs } from '@setvi/shared/components/scategory';

import { SelectedActionButton, PopupActionButton } from './actions';
import { useResourcesContent } from './useResourcesContent';
import { BaseSubheader } from '../../base/subheader';
import { Selected } from './selected';
import { ListContainer } from '../list';

interface ContentProps {
  type?: RESOURCE_TYPE;
  hideEditLink?: boolean;
  handleBack?(): void;
  handleInsert(link: Link): void;
  handleClose(): void;
  preselected?: Resource[];
}

const PanelContainer = withStyles({
  root: {
    height: '100%',
    display: 'grid'
  }
})(Box);

const HomeButton = withStyles(({ spacing }) => ({
  root: {
    padding: 0,
    minWidth: 0,
    marginRight: spacing(1)
  }
}))(Button);

interface PanelRows {
  selection: boolean;
  breadcrumbs: boolean;
}

const useStyles = makeStyles<Theme, PanelRows>(theme => ({
  containerTemplateRows: {
    gridTemplateRows: ({ selection, breadcrumbs }) => {
      if (selection && breadcrumbs) return 'auto 1fr auto';
      if (selection && !breadcrumbs) return '1fr auto';
      if (breadcrumbs) return 'auto 1fr';
      return '1fr';
    }
  },
  homeIcon: {
    color: theme.palette.text.secondary
  }
}));

export const Content = ({
  type = RESOURCE_TYPE.ALL_RESOURCES,
  hideEditLink,
  handleBack,
  handleInsert,
  handleClose,
  preselected
}: ContentProps) => {
  const {
    isExpanded,
    setIsExpanded,
    selectedResources,
    linkName,
    setLinkName,
    onSelect,
    searchTerm,
    setSearchTerm,
    categoryId,
    showResources,
    onLastCategoryLoaded,
    openCategory,
    onCategoryClick,
    hasSelection,
    hasBreadcrumbs,
    clearSelected,
    handleInsertLink,
    showCategories,
    onEmptyCategories,
    onEmptyResources,
    subHeaderText,
    submitText,
    handleSelectPage,
    showSelectedActionBtn,
    showHomeIcon
  } = useResourcesContent({
    type,
    handleInsert,
    handleClose,
    preselected,
    hideEditLink
  });

  const classes = useStyles({
    selection: hasSelection,
    breadcrumbs: hasBreadcrumbs
  });

  return (
    <ContentContainer
      header={
        <Header title="Add from SETVI" hasClose handleClose={handleClose} />
      }
      search={
        <SearchInput
          onChange={val => setSearchTerm(val)}
          defaultValue={searchTerm}
          loading={false}
          placeholder="Search..."
        />
      }
      backContent={
        <BaseSubheader
          handleBack={handleBack}
          text={subHeaderText}
          customBackButton={
            showHomeIcon && hasBreadcrumbs ? (
              <Box display="flex" alignItems="center">
                <HomeButton onClick={handleBack}>
                  <HomeIcon className={classes.homeIcon} />
                </HomeButton>
                <Divider orientation="vertical" flexItem />
              </Box>
            ) : (
              <></>
            )
          }
        />
      }>
      <PanelContainer className={classes.containerTemplateRows}>
        {hasBreadcrumbs && (
          <Breadcrumbs categoryId={categoryId} onClick={openCategory} />
        )}
        <ListContainer
          openCategory={onCategoryClick}
          categoryId={categoryId}
          onSelect={onSelect}
          onLastCategoryLoaded={onLastCategoryLoaded}
          showResources={showResources}
          searchTerm={searchTerm}
          type={type}
          showCategories={showCategories}
          onEmptyCategories={onEmptyCategories}
          onEmptyResources={onEmptyResources}
          selectedResources={selectedResources}
          onSelectPages={handleSelectPage}
        />

        {showSelectedActionBtn && (
          <SelectedActionButton
            setIsExpanded={setIsExpanded}
            length={selectedResources.length}
            onAddLinkName={handleInsertLink}
            linkName={linkName}
            setLinkName={setLinkName}
            onCancel={clearSelected}
            hideEditLink={hideEditLink}
            submitText={submitText}
          />
        )}
        <Selected
          resources={selectedResources}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          popupActionButton={
            <PopupActionButton
              linkName={linkName}
              submitText={submitText}
              hideEditLink={hideEditLink}
              onAddLinkName={handleInsertLink}
              setLinkName={setLinkName}
              onCancel={clearSelected}
            />
          }
          setLinkName={setLinkName}
          isResources
          setSelectedResources={onSelect}
        />
      </PanelContainer>
    </ContentContainer>
  );
};
