import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import {
  EmailOpen as EmailOpenedIcon,
  CreateLink as ResourceIcon,
  CreateLink2 as LinkIcon,
  MoreInfo as AlertIcon,
  CloseWhite as Close,
  DocumentIcon,
  Sync
} from 'Components/Shared/Icons';
import {
  getNotificationsQuery,
  markNotificationsMutation,
  NotificationsQueryKey
} from 'Services';
import { useSubscribedMutation } from 'Hooks/React-Query';

import { IconWrapper } from 'Components/Shared/IconWrapper';
import { NotificationType } from 'Enums/NotificationType';
import { GetNotificationTypeTitle } from 'Utils/getNotificationTypeTitle';
import { NotificationData } from './Notification';
import { useFirebaseNotifications } from './useFirebaseNotifications';

export interface NotificationByDayList {
  day: string;
  notifications: NotificationData[];
}

const DATE_FORMAT = 'MMM D, YYYY';

export const useNotificationsPanel = () => {
  const { palette } = useTheme();
  const [list, setList] = useState<NotificationByDayList[]>([]);
  const [haveUnread, setHaveUnread] = useState(false);
  const { firebaseNotification } = useFirebaseNotifications();
  const [page, setPage] = useState(1);
  const [size] = useState(25);

  const queryVariables = useMemo(
    () => ({
      pageNumber: page,
      pageSize: size
    }),
    [page, size]
  );

  // mark all notifications read mutation
  const { mutateAsync: markAllNotificationsRead } = useSubscribedMutation(
    markNotificationsMutation(),
    [NotificationsQueryKey.NOTIFICATIONS]
  );

  // Notification query
  const { data, isLoading: loading } = useQuery(
    getNotificationsQuery(queryVariables)
  );

  const withDescriptions = useMemo(
    () => [
      NotificationType.Admin,
      NotificationType.OutlookEmailOpen,
      NotificationType.LinkViewed,
      NotificationType.EmailSyncAccountError,
      NotificationType.EmailSyncAccountDisconnected,
      NotificationType.Share
    ],
    []
  );

  const hasDescription = useCallback(
    (type: NotificationType) =>
      withDescriptions.findIndex(
        notificationType => notificationType === type
      ) !== -1,
    [withDescriptions]
  );

  const withoutUser = useMemo(
    () => [
      NotificationType.Admin,
      NotificationType.SETVI,
      NotificationType.Share
    ],
    []
  );

  // @ts-ignore
  const hideUser = useCallback(
    (type: NotificationType) => {
      const isInArr = withoutUser.some(
        notificationType => notificationType === type
      );
      return !isInArr;
    },
    [withoutUser]
  );

  const hasMore = useMemo(() => data?.Result.length === size, [data, size]);

  const getNotificationIcon = useCallback(
    (type: NotificationType) => {
      let icon = null;
      const color = palette.common.white;

      // FIXME Admin and Setvi types needs ICON
      switch (type) {
        case NotificationType.LinkViewed:
          icon = <IconWrapper Icon={LinkIcon} color={color} />;
          break;
        case NotificationType.EmailOpen:
        case NotificationType.OutlookEmailOpen:
          icon = (
            <IconWrapper Icon={EmailOpenedIcon} color={color} enableStroke />
          );
          break;
        case NotificationType.EmailSyncAccountLinked:
          icon = <IconWrapper Icon={Sync} color={color} />;
          break;
        case NotificationType.EmailSyncAccountError:
          icon = <IconWrapper Icon={AlertIcon} color={color} />;
          break;
        case NotificationType.EmailSyncAccountDisconnected:
          icon = <IconWrapper Icon={Close} color={color} />;
          break;
        case NotificationType.PresentationTemplateCreate:
        case NotificationType.PresentationTemplateUpdate:
        case NotificationType.PresentationExported:
        case NotificationType.PresentationUploaded:
        case NotificationType.Share:
          icon = <DocumentIcon viewBox="0 -2 20 25" />;
          break;
        default: // For all resources and types which do not exist in Notification types
          icon = <IconWrapper Icon={ResourceIcon} color={color} />;
          break;
      }

      return icon;
    },
    [palette.common.white]
  );

  const getNotificationBgColor = useCallback(
    (type: NotificationType) => {
      let color = null;

      switch (type) {
        case NotificationType.LinkViewed:
        case NotificationType.OutlookEmailOpen:
          color = palette.success.main;
          break;
        case NotificationType.ResourceCreate:
        case NotificationType.ResourceReplace:
        case NotificationType.ResourceUpdate:
          color = '#1E96FC';
          break;
        case NotificationType.PresentationTemplateCreate:
        case NotificationType.PresentationTemplateUpdate:
        case NotificationType.PresentationExported:
        case NotificationType.PresentationUploaded:
        case NotificationType.Share:
          color = '#572BD3';
          break;
        default: // For all EmailSync types, Open Email and types which do not exist in Notification types
          color = '#EF1B81';
          break;
      }

      return color;
    },
    [palette.success.main]
  );

  const getNotificationData = useCallback(
    (item: any) => ({
      id: parseInt(item.ID, 10),
      time: `${moment.utc(item.DateTimeSent).local().format('L')} at ${moment
        .utc(item.DateTimeSent)
        .local()
        .format('h:mm A')}`,
      description: hasDescription(item.Type) ? item.Text : null,
      title: GetNotificationTypeTitle(item.Type),
      // TODO: Update user when API is updated
      // user: hideUser(item.Type) ? 'Test User' : null,
      user: null,
      icon: getNotificationIcon(item.Type),
      iconBackgroundColor: getNotificationBgColor(item.Type)
    }),
    [hasDescription, getNotificationBgColor, getNotificationIcon]
  );

  const getPrefixForDayEnty = (date: string) => {
    if (moment.utc().format(DATE_FORMAT) === date) {
      return 'Today - ';
    }
    if (moment.utc().subtract(1, 'days').format(DATE_FORMAT) === date) {
      return 'Yesterday - ';
    }
    return '';
  };

  const formatDate = useCallback(
    (date: string) => moment(date).utc().local().format(DATE_FORMAT),
    []
  );

  const fetchMoreNotifications = useCallback(() => {
    setPage(prev => prev + 1);
  }, []);

  useEffect(() => {
    if (data) {
      // TODO: Remove filter when all titles for types are available
      const newItems = data?.Result;
      // .filter(
      //   newNotif => GetNotificationTypeTitle(newNotif.Type)?.length > 0
      // );

      if (hasMore && !newItems.length) {
        fetchMoreNotifications();
        return;
      }

      // Get all unique dates from the list of notifications
      const uniqueDates = Array.from(
        new Set(
          newItems.map((notification: any) =>
            formatDate(notification.DateTimeSent)
          )
        )
      );

      // Create array of notifications associated with date
      const formattedNotifications = uniqueDates.map(date => ({
        day: date,
        notifications: newItems
          .filter(
            (notification: any) =>
              formatDate(notification.DateTimeSent) === date
          )
          // format notifications
          .map((notification: any) => getNotificationData(notification))
      }));

      setList((prev: any) => [...prev, ...formattedNotifications]);

      const haveUnreadNotifications = newItems.some(
        (notification: any) => notification.NotificationRead === 0
      );
      const newNotificationInTheList =
        firebaseNotification &&
        !data?.Result.some(
          (notification: any) => notification.ID === firebaseNotification.ID
        );
      setHaveUnread(haveUnreadNotifications || newNotificationInTheList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formatDate, getNotificationData, firebaseNotification, size]);

  return {
    list,
    loading,
    fetchMoreNotifications,
    haveUnread,
    hasMore,
    getPrefixForDayEnty,
    markAllNotificationsRead
  };
};
