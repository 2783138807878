import { lazy } from 'react';

// Home
export const Home = lazy(() => import('pages/home'));

// Campaigns
export const MyCampaignsPage = lazy(
  () => import('Components/Campaigns/MyCampaigns')
);

export const CompanyCampaign = lazy(() => import('Components/CompanyCampaign'));
export const CreateCampaign = lazy(
  () => import('Components/Campaigns/CreateCampaign')
);
export const Details = lazy(() => import('Components/Campaigns/Detail'));
export const FollowUp = lazy(() => import('Components/Campaigns/FollowUp'));

// Resources
export const Resources = lazy(() => import('Components/Resources'));
export const Favorites = lazy(() => import('Components/Favorites'));

// Workspaces
export const ContactsAndGroups = lazy(
  () => import('pages/contacts-and-groups')
);
export const Contacts = lazy(
  () => import('pages/contacts-and-groups/contacts')
);
export const Groups = lazy(() => import('pages/contacts-and-groups/groups'));
export const MyFiles = lazy(() => import('Components/Workspaces/MyFiles'));
export const MyPresentation = lazy(
  () => import('Components/Workspaces/MyPresentations')
);
export const PresentationDetails = lazy(
  () =>
    import(
      'Components/Workspaces/MyPresentations/Presentations/PresentationDetails'
    )
);
export const TemplateDetails = lazy(
  () =>
    import('Components/Workspaces/MyPresentations/Templates/TemplateDetails')
);
export const WorkspacesSnippets = lazy(
  () => import('Components/Workspaces/Snippets')
);

// Outbox
export const Outbox = lazy(() => import('pages/outbox'));
export const OutlookDetails = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-details')
);
export const OutlookViews = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-views')
);
export const MultipleRecipientEmailDetails = lazy(
  () => import('pages/outbox/subpages/emails/email-details/multiple-recipient')
);
export const SingleRecipientEmailDetails = lazy(
  () => import('pages/outbox/subpages/emails/email-details/single-recipient')
);
export const Emails = lazy(() => import('pages/outbox/subpages/emails'));
export const OutboxMyCampaigns = lazy(
  () => import('pages/outbox/subpages/campaigns')
);
export const Outlook = lazy(() => import('pages/outbox/subpages/outlook'));
export const OutboxGetLink = lazy(
  () => import('pages/outbox/subpages/get-links')
);
export const Overview = lazy(
  () =>
    import(
      'pages/outbox/subpages/emails/email-details/multiple-recipient/subpages/overview'
    )
);
export const EmailDetails = lazy(
  () =>
    import(
      'pages/outbox/subpages/emails/email-details/multiple-recipient/subpages/email-details'
    )
);
export const OutlookEmailDetails = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-email-details')
);

// Reporting
export const ReportingDetails = lazy(() => import('pages/reporting-details'));

// Admin Resources
export const EmailQueue = lazy(() => import('pages/email-queue'));
export const AdminResources = lazy(() => import('pages/admin/pages/resources'));
export const AdminCategoryDetails = lazy(
  () => import('pages/admin/pages/resources/pages/category/pages/details')
);
export const CreateCategory = lazy(
  () => import('pages/admin/pages/resources/pages/category/pages/create')
);
export const EditCategory = lazy(
  () => import('pages/admin/pages/resources/pages/category/pages/edit')
);
export const EditResource = lazy(
  () => import('pages/admin/pages/resources/pages/edit')
);

// Admin Campaigns
export const AdminCampaigns = lazy(() => import('pages/admin/pages/campaigns'));
export const AdminCampaignDetails = lazy(
  () => import('pages/admin/pages/campaigns/pages/details')
);
export const AdminCampaignDetailsContent = lazy(
  () => import('pages/admin/pages/campaigns/pages/details/pages/main')
);
export const AdminCampaignDetailsRecipients = lazy(
  () => import('pages/admin/pages/campaigns/pages/details/pages/recipients')
);

export const AdminCampaignsTable = lazy(
  () => import('pages/admin/pages/campaigns/pages/table')
);
export const CreateAdminCampaign = lazy(
  () => import('pages/admin/pages/campaigns/pages/create')
);
export const UpdateAdminCampaign = lazy(
  () => import('pages/admin/pages/campaigns/pages/update')
);

// Settings
export const Settings = lazy(() => import('Components/Settings'));
export const Analytics = lazy(() => import('Components/Analytics'));

// Authentication
export const Authenticate = lazy(() => import('pages/login'));

// Intelligence
export const IntelligenceGlobal = lazy(
  () => import('pages/intelligence-global')
);
export const IntelligenceGlobalOverview = lazy(
  () => import('pages/intelligence-global/sub-pages/overview')
);
export const IntelligenceGlobalIntersted = lazy(
  () => import('pages/intelligence-global/sub-pages/interested')
);
export const IntelligenceGlobalNotInterested = lazy(
  () => import('pages/intelligence-global/sub-pages/not-interested')
);

// Intelligence
export const CustomerDetails = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details'
    )
);
export const IntelligenceOverview = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details/sub-pages/overview'
    )
);
export const IntelligenceProducts = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details/sub-pages/products'
    )
);
export const IntelligenceTimeline = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details/sub-pages/timeline'
    )
);
export const IntelligenceInterested = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details/sub-pages/interested'
    )
);
export const IntelligenceNotInterested = lazy(
  () =>
    import(
      'pages/intalligence-new/sub-pages/customers/sub-pages/customer-details/sub-pages/not-interested'
    )
);

// Intelligence New
export const IntelligenceNew = lazy(() => import('pages/intalligence-new'));
export const IntelligenceStoppedPurchasing = lazy(
  () => import('pages/intalligence-new/sub-pages/stopped-purchasing-charts')
);
export const IntelligenceCrossSellOpportunity = lazy(
  () => import('pages/intalligence-new/sub-pages/cross-sell-opportunity')
);
export const IntelligenceNewOpenOrders = lazy(
  () => import('pages/intalligence-new/sub-pages/open-orders-charts')
);
export const IntelligenceNewAR = lazy(
  () => import('pages/intalligence-new/sub-pages/ar-charts')
);
export const IntelligenceNewCustomer = lazy(
  () => import('pages/intalligence-new/sub-pages/customers')
);
export const IntelligenceNewCustomerList = lazy(
  () => import('pages/intalligence-new/sub-pages/customers/sub-pages/list')
);
export const IntelligenceNewCategory = lazy(
  () => import('pages/intalligence-new/sub-pages/category')
);
export const IntelligenceNewProducts = lazy(
  () => import('pages/intalligence-new/sub-pages/products')
);
export const IntelligenceNewAllInterested = lazy(
  () => import('pages/intalligence-new/sub-pages/all-interested')
);

// Products
export const Products = lazy(() => import('pages/products'));
