import { CircularProgress, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useDialog } from '@setvi/shared/providers/dialog';
import { Star, StarBorder, FolderOpen } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

import {
  GetResourceTypeName,
  normalizeChildResourcesToLink,
  normalizeResourceToLink
} from '@setvi/shared/utils';
import moment from 'moment';
import { ISMenuItem } from '@setvi/shared/components/sdropdown-menu/MenuItem';
import {
  AddFile,
  SelectIcon,
  TrashIcon
} from '@setvi/shared/components/sicons';
import { DrawerContent, ResourceType } from '@setvi/shared/enums';
import { SConfirmDialog } from '@setvi/shared/components';
import { useSubscribedMutation } from '@setvi/shared/hooks';
import { useSnackbar } from 'notistack';
import {
  ChildResource,
  ResourceCategoryClass,
  Resource as ResourceProps
} from '@setvi/shared/interfaces';
import { ResourcesQueryKey, deleteUserResourceMutation } from 'Services';
import { ROUTES } from 'enumsV2';
import { useComposeEmail } from 'Hooks/useComposeEmail';
import {
  CreateLink,
  CreatePresentation,
  Download,
  Email,
  MoreInfo
} from 'Components/Shared/Icons';
import {
  GetLink,
  MoreInfo as MoreInfoDialog,
  ResourceChildren
} from '../Dialogs';
import { PresentationDialog as CreatePresentationDialog } from '../../../Workspaces/MyPresentations/Components/PresentationDialog';
import { useFavoritesActions } from './useFavorites';

const useStyles = makeStyles(theme => ({
  dropdownIconMarginTop: {
    marginTop: theme.spacing(1)
  },
  addPresentationIconFontSize: {
    fontSize: '1.6rem'
  },
  folderIcon: {
    height: 22
  },
  favoriteStar: {
    color: '#F08B0F'
  }
}));

enum DropdownItemsTypes {
  pdf = 'pdf',
  email = 'email',
  moreInfo = 'moreInfo',
  download = 'download',
  viewLink = 'viewLink',
  delete = 'delete',
  favorites = 'favorites',
  getLink = 'getLink',
  goToCategory = 'goToCategory',
  createPresentation = 'createPresentation'
}

type ItemType = keyof typeof DropdownItemsTypes;

interface Resource extends ISMenuItem {
  itemType?: ItemType;
}

export type DropdownItemsType = ItemType[];

export const resourcesItems: DropdownItemsType = [
  'pdf',
  'email',
  'favorites',
  'download',
  'getLink',
  'moreInfo',
  'delete'
];

const useDropdownResource = (refetchLoading = false) => {
  const classes = useStyles();
  const { open } = useComposeEmail();
  const { isLoading, handleFavorites } = useFavoritesActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();

  const { isLoading: isDeleteLoading, mutateAsync: deleteResource } =
    useSubscribedMutation(deleteUserResourceMutation(), [
      ResourcesQueryKey.USER_RESOURCES,
      ResourcesQueryKey.FAVORITES_RESOURCES
    ]);

  const onEmailResource = useCallback(
    (resource: ResourceProps) => {
      open({
        linksData: normalizeResourceToLink(resource),
        drawerContent: DrawerContent.reviewLinks
      });
    },
    [open]
  );

  const onChildResourceSelected = useCallback(
    (parentResource: ResourceProps, selected: ChildResource[]) => {
      open({
        linksData: normalizeChildResourcesToLink(selected, parentResource.Name),
        drawerContent: DrawerContent.reviewLinks
      });
    },
    [open]
  );

  const onDownload = useCallback((resource: ResourceProps) => {
    window.open(resource?.DownloadURLWithSas, '_blank');
  }, []);

  const handleMoreInfo = useCallback(
    (resource: ResourceProps) => {
      const informations = [
        {
          label: 'Resource type',
          value: GetResourceTypeName(resource?.ResourceTypeID)
        },
        {
          label: 'Last updated',
          value: `${moment
            .utc(resource?.ResourceLastChangedDate)
            .format('MM/DD/YYYY')} (${moment
            .utc(resource?.ResourceLastChangedDate)
            .local()
            .fromNow()})`
        },
        {
          label: 'Shareable',
          value: resource?.IsShareable ? 'Yes' : 'No'
        },
        {
          label: 'Category name',
          value: resource?.CategoryName
        }
      ];
      openDialog(
        <MoreInfoDialog
          informations={informations}
          thumbURL={resource?.ThumbURLWithSas}
          breadcrumbs={resource?.Breadcrumbs}
          comment={resource?.Comment}
        />
      );
    },
    [openDialog]
  );

  const onGoToCategory = useCallback(
    (resource: ResourceProps) => {
      const link =
        resource.Breadcrumbs[0]?.ResourceCategoryClass ===
        ResourceCategoryClass.MY_RESOURCES
          ? ROUTES.WORKSPACES_FILES
          : ROUTES.RESOURCES;

      navigate(`${link}?categoryId=${resource?.CategoryID}`);
    },
    [navigate]
  );

  const onDeleteResource = useCallback(
    (resource: ResourceProps) => {
      openDialog(
        <SConfirmDialog
          title="Delete Resource"
          description="Are you sure you want to delete this resource?"
          type="delete"
          confirmText="Yes, Delete"
          loading={isDeleteLoading}
          onConfirm={() => {
            deleteResource({ resourceid: resource.ResourceID }).then(
              response => {
                if (response?.Errors) return;
                enqueueSnackbar('Resource has been deleted successfully.', {
                  variant: 'success'
                });
              }
            );
            closeDialog();
          }}
          onClose={closeDialog}
        />
      );
    },
    [openDialog, closeDialog, isDeleteLoading, deleteResource, enqueueSnackbar]
  );

  const createDropdownMenu = useCallback(
    (
      resource: ResourceProps,
      dropdownItems: DropdownItemsType = []
    ): ISMenuItem[] => {
      const isNotDownloadable =
        !resource?.IsDownloadable || !resource?.DownloadURLWithSas;
      const isNotShareable = !resource?.IsShareable;
      const hasSubmenu = [
        ResourceType.Presentation,
        ResourceType.PdfDocument
      ].includes(resource?.ResourceTypeID);

      const allItems: Resource[] = [
        {
          itemType: DropdownItemsTypes.email,
          icon: <Email />,
          text: 'Email',
          subText: 'Send this resource in an email',
          disabled: isNotShareable,
          tooltipText: isNotShareable && 'This resource is not shareable',
          closeOnClick: !hasSubmenu,
          ...(!hasSubmenu && {
            onClick: () => onEmailResource(resource)
          }),
          ...(hasSubmenu && {
            submenu: [
              {
                icon: <AddFile viewBox="0 0 23 23" />,
                text: `Add full ${
                  resource.ResourceTypeID === ResourceType.PdfDocument
                    ? 'PDF'
                    : 'PPT'
                }`,
                subText: 'Add entire file with all pages',
                onClick: () => onEmailResource(resource)
              },
              {
                icon: <SelectIcon viewBox="0 0 23 23" />,
                text: 'Select Pages',
                subText: 'Add only relevant pages',
                onClick: () =>
                  openDialog(
                    <ResourceChildren
                      confirm={selected =>
                        onChildResourceSelected(resource, selected)
                      }
                      resource={resource}
                    />
                  )
              }
            ]
          })
        },
        {
          itemType: DropdownItemsTypes.favorites,
          icon: (
            <>
              {(isLoading || refetchLoading) && <CircularProgress size={20} />}
              {!isLoading && !refetchLoading && (
                <>
                  {resource.IsFavorite ? (
                    <Star className={classes.favoriteStar} />
                  ) : (
                    <StarBorder />
                  )}
                </>
              )}
            </>
          ),
          disabled: isLoading || refetchLoading,
          text: resource.IsFavorite
            ? 'Remove from Favorites'
            : 'Add to Favorites',
          subText: resource.IsFavorite
            ? 'Remove this resource from your favorites'
            : 'Add this resource to your favorites',
          closeOnClick: false,
          onClick: () =>
            handleFavorites(resource.ResourceID, resource.IsFavorite)
        },
        {
          itemType: DropdownItemsTypes.download,
          icon: <Download />,
          text: 'Download',
          subText: 'Download this resource to your device',
          onClick: () => onDownload(resource),
          disabled: isNotDownloadable,
          tooltipText: isNotDownloadable && 'This resource is not downloadable'
        },
        {
          itemType: DropdownItemsTypes.createPresentation,
          icon: <CreatePresentation />,
          text: 'Create Presentation',
          subText: 'Create a presentation with this resource',
          onClick: () =>
            openDialog(
              <CreatePresentationDialog
                withResources={[resource.ResourceID || '']}
              />
            )
        },
        {
          itemType: DropdownItemsTypes.getLink,
          icon: <CreateLink />,
          text: 'Get link',
          subText: 'Copy a link to paste anywhere',
          onClick: () => openDialog(<GetLink resourceId={resource.ID} />),
          disabled: isNotShareable,
          tooltipText: isNotShareable && 'This resource is not shareable'
        },
        {
          itemType: DropdownItemsTypes.goToCategory,
          icon: <FolderOpen />,
          text: 'Go to Category',
          subText: 'Jump into the main category',
          onClick: () => onGoToCategory(resource)
        },
        {
          itemType: DropdownItemsTypes.moreInfo,
          icon: <MoreInfo />,
          text: 'More Info',
          subText: 'More details about this resource',
          onClick: () => handleMoreInfo(resource)
        },
        resource.ResourceClass === ResourceCategoryClass.MY_RESOURCES && {
          itemType: DropdownItemsTypes.delete,
          icon: <TrashIcon />,
          text: 'Delete',
          subText: 'Delete this resource',
          onClick: () => onDeleteResource(resource),
          color: 'danger'
        }
      ];

      if (dropdownItems.length) {
        return allItems.filter(dropdownItem =>
          dropdownItems.includes(dropdownItem.itemType)
        );
      }

      return allItems;
    },
    [
      isLoading,
      refetchLoading,
      classes.favoriteStar,
      onEmailResource,
      openDialog,
      onChildResourceSelected,
      handleFavorites,
      onDownload,
      onGoToCategory,
      handleMoreInfo,
      onDeleteResource
    ]
  );

  return {
    createDropdownMenu
  };
};

export default useDropdownResource;
