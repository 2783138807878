import { FC } from 'react';
import { Box } from '@material-ui/core';
import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { EmptySnippets } from './EmptySnippets';
import { Tabs } from '../tabs';
import { useContent } from './useContent';
import { Preview } from '../preview';
import { List } from '../list';
import { SkeletonSnippets } from './SkeletonSnippets';
import { SSearchInput } from '../../../sforms';

export enum SNIPPETS_TAB {
  mySnippets = 0,
  sharedSnippets = 1
}

interface ContentProps {
  handleBack(): void;
  handleClose(): void;
  insertMessage(message: string): void;
}

export const Content: FC<ContentProps> = ({
  handleBack,
  handleClose,
  insertMessage
}) => {
  const {
    showSearch,
    currentTab,
    setCurrentTab,
    previewSnippet,
    setPreviewSnippet,
    snippets,
    loading,
    teamSnippets,
    searchTerm,
    setSearchTerm,
    handleOnBack
  } = useContent();

  return (
    <ContentContainer
      header={
        <Header title="Add from SETVI" hasClose handleClose={handleClose} />
      }
      backContent={
        <BaseSubheader
          handleBack={!previewSnippet ? handleBack : handleOnBack}
          text={`Snippets ${previewSnippet ? 'Details' : ''}`}
        />
      }>
      <>
        {!previewSnippet ? (
          <Box display="flex" flexDirection="column" width="100%" height="100%">
            <Tabs currentTab={currentTab} changeTab={setCurrentTab} />

            {showSearch && (
              <Box p={4}>
                <SSearchInput
                  defaultValue={searchTerm}
                  onChange={setSearchTerm}
                  placeholder="Search"
                />
              </Box>
            )}

            <Box
              width="100%"
              height="100%"
              flex={1}
              style={{
                overflowX: 'hidden',
                overflowY: 'auto'
              }}>
              {loading && <SkeletonSnippets />}
              {(snippets.length > 0 || teamSnippets.length > 0) && !loading ? (
                <List
                  setPreviewSnippet={setPreviewSnippet}
                  snippets={
                    currentTab === SNIPPETS_TAB.mySnippets
                      ? snippets
                      : teamSnippets
                  }
                />
              ) : (
                <Box height="100%" overflow="hidden">
                  <EmptySnippets />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Preview snippet={previewSnippet} insertMessage={insertMessage} />
        )}
      </>
    </ContentContainer>
  );
};
