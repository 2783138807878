import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Breadcrumbs as MuiBreadcrumbs
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

interface BreadcrumbLink {
  id: number;
  name: string;
  link: string;
  current: boolean;
}

export interface BreadcrumbProps {
  BreadcrumbIcon: FC;
  breadcrumbs: Array<BreadcrumbLink>;
}

export const BreadcrumbCurrentLink = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    fontWeight: 600
  }
}))(Typography);

export const BreadcrumbIconWrapper = withStyles(({ palette, spacing }) => ({
  root: {
    color: palette.text.secondary,
    marginRight: spacing(1)
  }
}))(Box);

const useStyles = makeStyles(theme => ({
  breadcrumbLink: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  }
}));

export const Breadcrumb: FC<BreadcrumbProps> = ({
  BreadcrumbIcon,
  breadcrumbs
}) => {
  const classes = useStyles();
  return (
    <>
      <BreadcrumbIconWrapper>
        <BreadcrumbIcon />
      </BreadcrumbIconWrapper>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map(breadcrumb =>
          breadcrumb.current ? (
            <BreadcrumbCurrentLink key={breadcrumb.id}>
              {breadcrumb.name}
            </BreadcrumbCurrentLink>
          ) : (
            <Link
              key={breadcrumb.id}
              className={classes.breadcrumbLink}
              to={breadcrumb.link}>
              {breadcrumb.name}
            </Link>
          )
        )}
      </MuiBreadcrumbs>
    </>
  );
};
