import { useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Category, Link, Resource } from '@setvi/shared/interfaces';
import { RESOURCE_TYPE } from '@setvi/shared/enums';
import { getLinkItemFromResource } from '@setvi/shared/utils';
import { useSelected } from '../../../../hooks/selected';
import { useDialog } from '../../../../providers';
import { SelectPages } from './select-pages';

interface UseResourcesContentProps {
  type?: RESOURCE_TYPE;
  handleClose?(): void;
  handleInsert(link: Link): void;
  preselected?: Resource[];
  hideEditLink?: boolean;
}

export const useResourcesContent = ({
  type = RESOURCE_TYPE.ALL_RESOURCES,
  handleInsert,
  handleClose,
  preselected = [],
  hideEditLink
}: UseResourcesContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [linkName, setLinkName] = useState('');
  const { openDialog, closeDialog } = useDialog();

  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [showResources, setShowResources] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const [isCategoriesEmpty, setIsCategoriesEmpty] = useState(false);
  const [isResourcesEmpty, setIsResourcesEmpty] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  // const { sendCategoryAnalytics } = useAnalytics();
  const { selected, handleSelect } = useSelected<Resource>({
    dataKeyGetter: (resource: Resource) => resource.ID,
    preselected
  });

  const dialogId = 'select-pages';

  const handleSelectPage = (resource: Resource) => {
    openDialog(
      <SelectPages
        resource={resource}
        onClose={() => closeDialog(dialogId)}
        onConfirm={sp => {
          const selectedPagesFormatted: Resource[] = sp.map(page => ({
            ...page,
            ID: page.ID,
            ResourceID: page.ItemID,
            Name: `${resource.Name} (${page.SortOrder})`,
            ResourceTypeID: resource.ResourceTypeID,
            Key: 'child'
          }));
          handleSelect(true, selectedPagesFormatted);
          closeDialog(dialogId);
        }}
      />,
      {
        id: dialogId
      }
    );
  };

  const openCategory = (categoryID: number) => {
    setCategoryId(categoryID);
    setShowResources(false);
    setShowCategories(true);

    setIsCategoriesEmpty(false);
    setIsResourcesEmpty(false);
    // TODO: Implemente category analtyics when graphql is moved to shared folder
    // sendCategoryAnalytics({ CategoryID: categoryID });
  };

  const onCategoryClick = (rowData: Category) => {
    openCategory(rowData.ID);
  };

  const onEmptyCategories = () => {
    setShowCategories(false);
    setShowResources(true);
    setIsCategoriesEmpty(true);
  };

  const onLastCategoryLoaded = useCallback(() => {
    if (!isResourcesEmpty) {
      setShowResources(true);
    }
  }, [isResourcesEmpty]);

  const onEmptyResources = useCallback(() => {
    setIsResourcesEmpty(true);
    if (!isCategoriesEmpty) {
      setShowResources(false);
    }
  }, [isCategoriesEmpty]);

  const hasSelection = useMemo(() => selected.length > 0, [selected]);

  const hasBreadcrumbs = useMemo(
    () => searchTerm.length < 1 && type !== RESOURCE_TYPE.FAVORITE_RESOURCES,
    [searchTerm, type]
  );

  const subHeaderText = useMemo(() => {
    if (type === RESOURCE_TYPE.FAVORITE_RESOURCES) return 'Favorites';

    if (type === RESOURCE_TYPE.REQURIED_READING)
      return 'Add from Required Reading';

    return type === RESOURCE_TYPE.USER_RESOURCES ? 'My Resources' : 'Resources';
  }, [type]);

  const clearSelected = useCallback(() => {
    setIsExpanded(false);
    setLinkName('');

    if (type === RESOURCE_TYPE.REQURIED_READING) {
      const staticDocs = selected.filter(elem => !preselected.includes(elem));
      handleSelect(false, staticDocs);
      handleClose();
    } else if (!hideEditLink) handleSelect(false, selected);
    else
      handleSelect(
        false,
        selected.filter(item => !item?.Key)
      );
  }, [type, hideEditLink, handleSelect, selected, handleClose, preselected]);

  const handleInsertLink = useCallback(
    (name: string) => {
      handleInsert({
        Name: name,
        Placeholder: uuidv4(),
        Item: {
          Items: selected.map(item => getLinkItemFromResource(item))
        }
      });
      clearSelected();
    },
    [handleInsert, selected, clearSelected]
  );

  const submitText = useMemo(() => {
    if (preselected.length) {
      return 'Confirm';
    }
    if (hideEditLink) {
      return 'Add';
    }
    return 'Add link';
  }, [hideEditLink, preselected]);

  // Check if show selected action is showed. When we are in required reading we always show
  // it otherwise we check if there is any selected resource
  const showSelectedActionBtn = useMemo(() => {
    const condition = selected?.length > 0 || preselected?.length > 0;

    if (type === RESOURCE_TYPE.REQURIED_READING && condition) return true;
    if (condition) return true;

    return false;
  }, [preselected?.length, selected?.length, type]);

  const showHomeIcon = useMemo(
    () => type !== RESOURCE_TYPE.REQURIED_READING,
    [type]
  );

  return {
    isExpanded,
    setIsExpanded,
    selectedResources: selected,
    linkName,
    setLinkName,
    onSelect: handleSelect,
    searchTerm,
    setSearchTerm,
    categoryId,
    showResources,
    setShowResources,
    openCategory,
    onCategoryClick,
    hasSelection,
    hasBreadcrumbs,
    clearSelected,
    handleInsertLink,
    showCategories,
    onEmptyCategories,
    onEmptyResources,
    subHeaderText,
    onLastCategoryLoaded,
    submitText,
    handleSelectPage,
    showSelectedActionBtn,
    showHomeIcon
  };
};
