import { Box, makeStyles } from '@material-ui/core';
import { DateLabel } from './date-label';
import { TimeLineItems } from './time-line-items';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    padding: spacing(5)
  },
  content: {
    paddingLeft: spacing(3)
  }
}));

export interface TimelineItemBase {
  id: string;
  timestamp: string | Date;
}

export type GroupedItemsByDate<T> = { [date: string]: T[] };

interface TimelineProps<T> {
  uniqueDates: string[];
  groupedItems: GroupedItemsByDate<T>;
  renderLeft?: (item: T) => JSX.Element;
  renderRight?: (item: T) => JSX.Element;
}

export function STimeline<T extends TimelineItemBase>({
  uniqueDates,
  groupedItems,
  renderLeft,
  renderRight
}: TimelineProps<T>) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {uniqueDates.map(date => (
        <Box key={date} className={classes.content}>
          <DateLabel date={date} />
          <TimeLineItems
            items={groupedItems[date]}
            renderLeft={renderLeft}
            renderRight={renderRight}
          />
        </Box>
      ))}
    </Box>
  );
}
