import { useMemo } from 'react';
import { SBase } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { useNavigate } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';
import { Presentation } from '@setvi/shared/interfaces';
import { Content } from './Content';

const Description = withStyles({
  root: {
    color: 'rgba(21, 25, 32, 0.5)',
    fontSize: 14
  }
})(Typography);

export enum DIALOG_TYPE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE'
}

const dialogHeader = {
  [DIALOG_TYPE.CREATE]: {
    title: 'Create Presentation',
    description: ''
  },
  [DIALOG_TYPE.EDIT]: {
    title: 'Rename Presentation',
    description: 'Change the name for presentation, enter a new name below'
  },
  [DIALOG_TYPE.DUPLICATE]: {
    title: 'Duplicate Presentation',
    description:
      'Create a copy of an existing presentation, you can decide name below'
  }
};

export interface PresentationDialogProps {
  onClose?: () => void;
  onSuccess?: (presentation?: Presentation) => void;
  withResources?: string[];
  presentation?: Presentation;
}

export const PresentationDialog = ({
  onClose,
  onSuccess,
  withResources,
  presentation
}: PresentationDialogProps) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();

  const dialogType = useMemo(() => {
    if (!presentation) {
      return DIALOG_TYPE.CREATE;
    }
    if (presentation && !withResources) {
      return DIALOG_TYPE.EDIT;
    }
    return DIALOG_TYPE.DUPLICATE;
  }, [presentation, withResources]);

  return (
    <SBase
      width={495}
      header={{
        title: dialogHeader[dialogType].title,
        description: dialogHeader[dialogType].description && (
          <Description>{dialogHeader[dialogType].description}</Description>
        ),
        onClose: closeDialog
      }}>
      <Content
        goTo={(path: string) => navigate(path)}
        onClose={onClose || closeDialog}
        onSuccess={onSuccess}
        dialogType={dialogType}
        withResources={withResources}
        presentation={presentation}
      />
    </SBase>
  );
};
