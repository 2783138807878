import { Box } from '@material-ui/core';
import { useResourceChildrenStyles } from '../styles';
import { SPagesList, Bookmark, ResourceInfo, Document } from '../components';
import { Resource, ChildResource } from '../../../interfaces';

export interface ListProps {
  resource: Resource;
  selected: ChildResource[];
  handleSelect: (isChecked: boolean, selected: ChildResource[]) => void;
  activePage: ChildResource;
  setActivePage: (page: ChildResource) => void;
  loading: boolean;
  list: ChildResource[];
}

export const PDFResourceChildrenList = ({
  list,
  loading,
  resource,
  selected,
  handleSelect,
  activePage,
  setActivePage
}: ListProps) => {
  const classes = useResourceChildrenStyles();

  const handleActiveFromBookmark = (bookmarkedIndex: number) => {
    const newActivePage = list.find(page => page.SortOrder === bookmarkedIndex);
    setActivePage(newActivePage);
  };

  return (
    <>
      <Box className={classes.resourceInfoContainer}>
        <ResourceInfo
          thumbUrl={resource?.ThumbURLWithSas}
          name={resource?.Name}
          resourceID={resource?.ResourceID}
          resourceTypeID={resource?.ResourceTypeID}
          resourceTypeName={resource?.ResourceTypeName}
        />
        <Bookmark
          id={resource.ResourceID}
          activePage={activePage}
          disabled={loading}
          setActivePage={handleActiveFromBookmark}
        />
      </Box>
      <Box className={classes.container}>
        <SPagesList
          list={list}
          loading={loading}
          selected={selected}
          handleSelect={handleSelect}
          activePage={activePage}
          setActivePage={setActivePage}
        />
        <Box className={classes.activePage}>
          <Document
            document={resource}
            loading={loading}
            activePage={activePage?.SortOrder}
          />
        </Box>
      </Box>
    </>
  );
};
