import { makeStyles } from '@material-ui/core';
import { distances } from '../../../../styles';

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  },
  popper: {
    marginTop: 4,
    borderRadius: 8,
    maxHeight: 250,
    height: 'auto',

    '& .MuiPaper-root': {
      borderRadius: 8,
      border: 'none',
      height: '100%'
    },

    '& .MuiAutocomplete-listbox': {
      height: '100%',
      maxHeight: 250,
      paddingBlock: 0,
      borderRadius: 8,
      overflowY: 'auto',
      border: `1px solid ${theme.palette.primary.main}`
    }
  }
}));
