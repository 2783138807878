import { cloneElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDialog } from '@setvi/shared/providers';
import { ROUTES } from 'enumsV2';

interface IRouterModal {
  route: string;
  children: JSX.Element;
  fallbackRoute?: ROUTES;
}

const RouterModal = ({ route, children, fallbackRoute }: IRouterModal) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();

  const handleNavigation = () => {
    if (fallbackRoute) navigate(fallbackRoute);
    else navigate(-1);
  };

  const onClose = () => {
    closeDialog('router-modal', handleNavigation);
  };

  useEffect(() => {
    const matched = location.pathname
      .replace(':id', '')
      .includes(route.replace(':id', ''));
    if (matched)
      openDialog(
        <>
          {cloneElement(children, {
            children: cloneElement(children.props.children, { onClose })
          })}
        </>,
        {
          preventClose: true,
          id: 'router-modal'
        }
      );
    // eslint-disable-next-line
  }, [location.pathname, children]);

  return <></>;
};

export default RouterModal;
