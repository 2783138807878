import { Box, Divider } from '@material-ui/core';

import { Snippet } from '@setvi/shared/interfaces';
import { SButton, SText } from '@setvi/shared/components';
import { DRAWER_WIDTH } from '@setvi/shared/components/sdrawer/base';

interface ItemProps {
  item: Snippet;
  handlePreviewClick(snippet: Snippet): void;
}

export const Item = ({ item, handlePreviewClick }: ItemProps) => (
  <Box key={item.id}>
    <SButton
      fullWidth
      variant="text"
      color="default"
      onClick={() => handlePreviewClick(item)}
      style={{
        padding: 22,
        display: 'flex',
        justifyContent: 'flex-start'
      }}>
      <Box
        textAlign="left"
        display="flex"
        flexDirection="column"
        gridGap={6}
        width={DRAWER_WIDTH - 55}>
        <SText title={item.title} maxLines={2} weight="bold" size="sm" />
        <SText size="xs" fontColor="#92929D">
          Last Modified: {item.lastModified}
        </SText>
      </Box>
    </SButton>
    <Divider />
  </Box>
);
