import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { SSearchInput as SearchInput } from '@setvi/shared/components/sforms/ssearch';
import { EmptySearchResults } from '@setvi/shared/components/sdrawer/resources/list/empty-states';
import { Link, LinkItemItems } from '@setvi/shared/interfaces';
import { Content } from '@setvi/shared/components/sdrawer/base';
import { Header } from '@setvi/shared/components/sdrawer/header';
import styled from 'styled-components';
import { Item } from './item';
import { Form } from '../Form';
import { Back } from './Back';
import { useEdit } from './useEdit';

interface EditProps {
  handleBack: () => void;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
  handleClose: () => void;
  hideEditLink?: boolean;
  link: Link;
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 180px;
  height: 100%;
`;

const ListContainer = withStyles({
  root: {
    overflowY: 'scroll'
  }
})(Box);

export const Edit = ({
  handleBack,
  handleInsert,
  handleRemoveLink,
  handleClose,
  hideEditLink,
  link
}: EditProps) => {
  const {
    searchResults,
    handleSubmit,
    handleRemoveItem,
    setSearchTerm,
    searchTerm
  } = useEdit({
    handleBack,
    handleInsert,
    handleRemoveLink,
    link
  });

  const { openDialog, closeDialog } = useDialog();

  const onDelete = useCallback(
    (linkItem: LinkItemItems) => {
      openDialog(
        <SConfirmDialog
          type="delete"
          title="Delete resource"
          description="Are you sure you want to delete this resource from the resource link?"
          additionalMessage="The action CANNOT be reverted back"
          onClose={() => closeDialog('delete-resource-link')}
          onConfirm={() => {
            handleRemoveItem(linkItem);
            closeDialog('delete-resource-link');
          }}
          confirmText="Delete"
          closeText="Keep it"
        />,
        {
          id: 'delete-resource-link'
        }
      );
    },
    [openDialog, closeDialog, handleRemoveItem]
  );

  return (
    <Content
      header={
        <Header
          title="Edit & Rename Link(s)"
          hasClose
          handleClose={handleClose}
        />
      }
      backContent={
        <Back
          handleBack={handleBack}
          handleRemoveLink={handleRemoveLink}
          link={link}
        />
      }
      search={<SearchInput onChange={setSearchTerm} placeholder="Search..." />}>
      <Container>
        {searchResults?.length ? (
          <ListContainer>
            {searchResults.map(resource => (
              <Item
                search={searchTerm}
                resource={resource}
                key={resource.ItemID}
                // show remove button only if the link is not a presentation. Note: presentation only have ParentID
                removeItem={!link.ParentID && onDelete}
              />
            ))}
          </ListContainer>
        ) : (
          <EmptySearchResults />
        )}
        <Form
          handleSubmit={handleSubmit}
          handleClose={handleBack}
          initialValue={link?.Name || ''}
          hideEditLink={hideEditLink}
          submitText="Update Link"
        />
      </Container>
    </Content>
  );
};
