import { AxiosMethods, AdminResourceApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import {
  ChangeResourceCategoryInput,
  ChangeAdminResourceStatusInput
} from './Types';

export enum AdminResourcesMutationsKey {
  CHANGE_RESOURCES_CATEGORY = 'CHANGE_RESOURCES_CATEGORY',
  DELETE_ADMIN_RESOURCE = 'DELETE_ADMIN_RESOURCE'
}

const STATUS_ID = ':statusId';
const RESOURCE_ID = ':resourceId';
const sourceCategoryID = ':sourceCategoryID';
const destinationCategoryID = ':destinationCategoryID';

export const changeAdminResourceStatusMutation = () => ({
  mutationFn: (params: ChangeAdminResourceStatusInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeResourceStatus.replace(
        STATUS_ID,
        params.statusId.toString()
      ).replace(RESOURCE_ID, params.resourceId.toString()),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const changeAdminResourcesCategoryMutation = () => ({
  mutationFn: (params: ChangeResourceCategoryInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeResourcesCategory.replace(
        sourceCategoryID,
        params.sourceCategoryID.toString()
      ).replace(destinationCategoryID, params.destinationCategoryID.toString()),
      method: AxiosMethods.PATCH,
      params,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const deleteAdminResourceMutation = () => ({
  mutationFn: (id: number) =>
    axiosHelper({
      endpoint: AdminResourceApi.DeleteResource.replace(':id', id.toString()),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});
