import { Box } from '@material-ui/core';
import { RESOURCE_TYPE } from '@setvi/shared/enums';
import { Resource } from '@setvi/shared/interfaces';
import { useSearchResults } from './useSearchResults';
import { List } from '../components/resource-list';
import SText from '../../sui/stext';

interface SearchResultsProps {
  onSelect: (isChecked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  rowKeyGetter: (data: any) => string | number;
  searchTerm?: string;
  sasToken?: string;
  algoliaKey?: string;
  selectedResources?: Resource[];
  resourceType: RESOURCE_TYPE;
}

export const AlgoliaSearchResults = ({
  onSelect,
  onSelectPages,
  rowKeyGetter,
  searchTerm,
  sasToken,
  algoliaKey,
  selectedResources = [],
  resourceType
}: SearchResultsProps) => {
  const {
    algoliaResultResources,
    isAlgoliaLoading,
    loadNextPage,
    totalCount,
    handleSelect
  } = useSearchResults({
    searchTerm,
    sasToken,
    algoliaKey,
    resourceType,
    onSelect
  });

  const emptyStateInfo = {
    imageSrc: '/images/no-resources.svg',
    title: 'No Match found.',
    subTitle: 'Please try again with a different search term.'
  };

  return (
    <Box p={2}>
      {algoliaResultResources.length > 0 && !isAlgoliaLoading && (
        <Box mb={2}>
          <SText weight="medium">Resources</SText>
        </Box>
      )}
      <List
        resources={algoliaResultResources}
        onSelect={handleSelect}
        selectedResources={selectedResources}
        fetchMore={() => {
          if (algoliaResultResources.length < totalCount && !isAlgoliaLoading)
            loadNextPage();
        }}
        rowKeyGetter={rowKeyGetter}
        loading={isAlgoliaLoading}
        isEmpty={!algoliaResultResources.length && !isAlgoliaLoading}
        emptyStateInfo={emptyStateInfo}
        onSelectPages={onSelectPages}
      />
    </Box>
  );
};
