import {
  OutlinedInput,
  withStyles,
  InputAdornment,
  Box,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useAlgolia } from '@setvi/shared/components/sresource/hooks/useAlgolia';
import { useCallback, useState } from 'react';
import { Search } from 'Components/Shared/Icons';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import SearchResults from './SearchResults';

const SearchField = withStyles(() => ({
  adornedStart: {
    backgroundColor: '#FAFAFB'
  },
  adornedEnd: {
    borderRadius: 10,
    backgroundColor: '#FAFAFB',
    fontSize: 'revert',
    '& fieldset': {
      borderColor: '#F1F1F5'
    }
  },
  input: {
    backgroundColor: '#FAFAFB',
    padding: '14px 14px',
    height: '15px'
  }
}))(OutlinedInput);

const useSearchStyles = makeStyles((theme: Theme) => ({
  activeAdorned: {
    color: theme.palette.primary.main
  },
  inactiveAdorend: {
    color: '#92929D'
  }
}));

const GlobalSearch = () => {
  const styles = useSearchStyles();
  const [query, setQuery] = useState<string>('');
  const [isSearchingInsideDocuments, setIsSearchingInsideDocuments] =
    useState<boolean>(false);

  const { algoliaKey, sasToken } = useAppContext();
  const {
    searchAlgoliaResources,
    resources,
    isLoading,
    isResultInsideDocuments
  } = useAlgolia({ sasToken, algoliaKey, hitsPerPage: 50 });

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setQuery(searchQuery);
      searchAlgoliaResources(searchQuery, true, false);
      setIsSearchingInsideDocuments(false);
    },
    [searchAlgoliaResources]
  );

  const handleSearchInsideDocuments = useCallback(
    (searchQuery: string) => {
      searchAlgoliaResources(searchQuery, false, true);
      setIsSearchingInsideDocuments(true);
    },
    [searchAlgoliaResources]
  );

  const handleSearchClose = useCallback(() => {
    setQuery('');
  }, []);

  return (
    <ClickAwayListener onClickAway={() => handleSearchClose()}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        width="520px"
        zIndex={10}>
        <SearchField
          placeholder="Search resources"
          type="text"
          value={query}
          onChange={event => handleSearch(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search
                className={clsx(
                  query ? styles.activeAdorned : styles.inactiveAdorend
                )}
              />
            </InputAdornment>
          }
          endAdornment={
            query && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="delete"
                  onClick={() => setQuery('')}
                  size="small">
                  <CloseIcon color="primary" />
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {query && (
          <SearchResults
            resources={resources}
            isLoading={isLoading}
            searchQuery={query}
            isUserSearchingInsideDocuments={isSearchingInsideDocuments}
            onBackToQuickSearch={handleSearch}
            isResultInsideDocuments={isResultInsideDocuments}
            onSearchInsideDocuments={handleSearchInsideDocuments}
            handleSearchClose={handleSearchClose}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default GlobalSearch;
