import { Box, Checkbox } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import React from 'react';
import { useLocationStyles } from './style';

interface LocationProps {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export const Location = ({ title, onChange, checked }: LocationProps) => {
  const styles = useLocationStyles();
  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        color="primary"
        icon={<CheckBoxOutlineBlank htmlColor="#E2E2EA" />}
        checked={checked}
        onChange={onChange}
      />
      <SText className={styles.title} title={title} />
    </Box>
  );
};
