import { Box, Divider, IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SText from '../../sui/stext';

export interface SHeaderProps {
  title: string;
  description?: JSX.Element | string;
  onClose?(): void;
}

const Container = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: `${spacing(3)}px ${spacing(4)}px ${spacing(3)}px ${spacing(4)}px`
  }
}))(Box);

const SHeader = ({ title, description, onClose }: SHeaderProps) => (
  <>
    <Container>
      <Box>
        <SText title={title} size="xl" weight="bold" />
        {!!description && typeof description === 'string' ? (
          <SText
            maxLines={2}
            variant="caption"
            color="textSecondary"
            title={description}
          />
        ) : (
          description
        )}
      </Box>
      {onClose ? (
        <IconButton aria-label="close" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Container>
    <Divider />
  </>
);

export default SHeader;
