import { Box, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { CheckCircleOutline } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert/Alert';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { CreateLinkButtons } from './Buttons';

interface IPreviewLink {
  onClose: () => void;
  linkName: string;
}

const usePreviewLinkStyles = makeStyles(theme => ({
  disabledInput: {
    color: theme.palette.text.primary
  },
  alert: {
    color: '#469E73',
    fontSize: '0.86em',
    display: 'flex',
    alignItems: 'center'
  }
}));

const PreviewLink = ({ onClose, linkName }: IPreviewLink) => {
  const styles = usePreviewLinkStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = useCallback(
    (link: string) => {
      navigator.clipboard.writeText(link);
      enqueueSnackbar(`Link has been copied successfully`, {
        variant: 'success'
      });
      onClose();
    },
    [enqueueSnackbar, onClose]
  );

  return (
    <>
      <Box mt={3} mb={4}>
        <Box mb={4}>
          <Alert
            icon={<CheckCircleOutline />}
            className={styles.alert}
            severity="success">
            Link created successfully.
          </Alert>
        </Box>
        <TextField
          InputProps={{ classes: { disabled: styles.disabledInput } }}
          disabled
          fullWidth
          variant="outlined"
          value={linkName}
        />
      </Box>
      <CreateLinkButtons
        confirmButtonText="Copy Link"
        onConfirm={() => handleConfirm(linkName)}
        onClose={onClose}
      />
    </>
  );
};

export default PreviewLink;
