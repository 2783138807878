import React from 'react';
import clsx from 'clsx';
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio
} from '@material-ui/core';
import { Field, FieldProps } from 'formik';

import SLabel from '../../../sui/slabel';
import { useStyles } from './style';

interface SFormikRadioProps {
  name: string;
  row?: boolean;
  label?: string;
  required?: boolean;
  labelClass?: string;
  options: SFormikRadioOption[];
  onChange?: (event: string) => void;
}

export interface SFormikRadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const SFormikRadio = ({
  row,
  name,
  label,
  options,
  required,
  labelClass,
  onChange
}: SFormikRadioProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ meta, form }: FieldProps) => (
        <FormControl>
          <FormLabel>
            {label ? (
              <SLabel
                title={label}
                className={clsx(labelClass, classes.title)}
                variant="body2"
                required={required}
              />
            ) : null}
          </FormLabel>
          <RadioGroup
            row={row}
            value={meta.value?.toString()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const currentValue = event.target.value;

              form.setFieldValue(name, currentValue);
              onChange?.(currentValue);
            }}
            name="row-radio-buttons-group">
            {options?.map(option => (
              <FormControlLabel
                key={option?.value}
                value={option?.value?.toString()}
                label={option?.label}
                disabled={option?.disabled}
                control={<Radio color="primary" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Field>
  );
};

export default SFormikRadio;
