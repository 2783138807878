import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { Field, FieldProps } from 'formik';
import DatePicker from 'react-datepicker';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { SFloatRight, SDatepickerInput } from '../../../../styled-components';

import { CalendarIcon } from '../../../sicons';
import SLabel from '../../../sui/slabel';
import { distances } from '../../../../styles';

/** ******************************************************************************************
 ** refrence https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
 ******************************************************************************************* */

const useStyles = makeStyles(() => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'transparent',

    '& .react-datepicker-wrapper': {
      width: '100% !important'
    }
  },
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  }
}));

interface SFormikDatepickerProps {
  name: string;
  label?: string;
  title?: string;
  required?: boolean;
  labelClass?: string;
  placeholder?: string;
  onChange?: (value: Date) => void;
}

const SFormikDatepicker = ({
  name,
  label,
  onChange,
  labelClass,
  title = 'date',
  required = false,
  placeholder = 'MM/DD/YYYY'
}: SFormikDatepickerProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box className={classes.paperBox}>
          <SLabel
            title={label}
            className={clsx(labelClass, classes.title)}
            variant="body2"
            required={required}
          />

          <Box position="relative" width="100%">
            <DatePicker
              customInput={<SDatepickerInput />}
              selected={meta.value}
              placeholderText={placeholder}
              required={required}
              title={title}
              name={field.name}
              value={meta.value}
              minDate={new Date()}
              onChange={(date: Date) => {
                const setDate = date.setHours(23, 59, 59);
                const formatedDate = new Date(setDate);

                form.setFieldValue(name, formatedDate);
                onChange?.(formatedDate);
              }}
            />

            <SFloatRight right="10px">
              <CalendarIcon htmlColor="#92929D" />
            </SFloatRight>
          </Box>
        </Box>
      )}
    </Field>
  );
};

export default SFormikDatepicker;
