import { useEffect, useState } from 'react';
import { EditorOptions } from 'tinymce';
import { v4 as uuidv4 } from 'uuid';

import {
  IHandleVariableOptions,
  IEditorVariables,
  VariableOption,
  SEditorType,
  IVariable
} from '../../interfaces';

export enum VariablesActions {
  DELETE = 'delete',
  INSERT = 'insert',
  UPDATE = 'update'
}

interface IUseVariables {
  variableOptions: VariableOption[];
  editorRef: SEditorType;
}

export const useVariables = ({ editorRef, variableOptions }: IUseVariables) => {
  const [variables, setVariables] = useState<IVariable[]>([]);
  const [currentVariable, _setCurrentVariable] = useState(null);
  let IsBracket = false;
  let prevKey = '';

  // We have a lot variables with old logic so this is backup to recognize old variables and replace them with new ones
  useEffect(() => {
    if (editorRef) {
      const content = editorRef.getBody();
      const oldVariables = content.querySelectorAll('label.variable');

      oldVariables.forEach((oldVar: HTMLElement) => {
        // Create a new <span> element
        const newSpan = document.createElement('span');
        newSpan.textContent = oldVar.textContent; // Copy the text content of the <a> element

        newSpan.classList.add('mce-mergetag'); // Add a new class or modify existing classes

        // Replace the <a> element with the new <span> element
        oldVar.parentNode.replaceChild(newSpan, oldVar);
      });
    }
  }, [editorRef]);

  const getCarretCoordiantes = (editorNode: SEditorType): DOMRect => {
    const bookmark: any = editorNode?.selection.getBookmark();

    const carretCooridnates = editorNode?.selection
      .getNode()
      .querySelector(`#${bookmark.id}_start`)
      .getBoundingClientRect();

    return carretCooridnates;
  };

  const deletevariable = (
    editorNode: SEditorType,
    preventDefault?: () => void
  ) => {
    const editor = editorRef || editorNode;
    const selection = editor?.selection;
    const selectedNode = selection?.getNode();

    if (
      selectedNode.nodeName === 'SPAN' &&
      selectedNode?.classList?.contains('mce-mergetag')
    ) {
      // If the selected node is an <a> tag, delete only the selected node
      const range = selection.getRng();
      range.setStartBefore(selectedNode);
      range.setEndAfter(selectedNode);
      selection.setRng(range);

      editor.execCommand('Delete');

      preventDefault?.();
    }
  };

  const editVariable = (newVar: string, editorNode: SEditorType) => {
    const selection = editorRef?.selection || editorNode?.selection;
    const varNode = selection?.getNode();
    varNode.textContent = newVar;

    setVariables(
      variables?.map(variable =>
        variable?.id === currentVariable?.id
          ? { ...variable, label: newVar }
          : variable
      )
    );
  };

  const handleCursorIsInsideVariable = (editor: SEditorType) => {
    const bookmark = editor.selection.getBookmark();
    editor.selection.moveToBookmark(bookmark);

    // Check if the cursor is inside a link
    const node = editor.selection.getNode();

    if (node.nodeName === 'SPAN' && node?.classList?.contains('mce-mergetag')) {
      // Move the cursor to the end of the link
      const range = editor.selection.getRng();
      range.setEndAfter(node);
      range.collapse(false);
      editor.selection.setRng(range);
      editor.execCommand('mceInsertContent', false, ' ');
    }
  };

  const insertVariable = (variable: string, editor?: SEditorType) => {
    const currentEditor = editor || editorRef;

    handleCursorIsInsideVariable(currentEditor);

    const id = uuidv4();

    currentEditor?.execCommand(
      'mceInsertContent',
      false,
      `<span id=${id} class='mce-mergetag' contenteditable="false">${variable}</span> `
    );

    setVariables(prev => [
      ...prev,
      {
        id,
        label: variable
      }
    ]);

    prevKey = '';

    setTimeout(() => {
      currentEditor.focus();
    }, 100);
  };

  const handleVariables = (
    action: VariablesActions,
    { variable, editorNode }: IHandleVariableOptions
  ) => {
    switch (action) {
      default:
        insertVariable(variable?.value, editorNode);
    }
  };

  // Remove whole link for backspace and delete key
  const handleKeyDown = async (
    e: {
      key: string;
      keyCode: number;
      shiftKey: boolean;
      target: any;

      preventDefault: () => void;
    },
    editorNode: SEditorType
  ) => {
    // IsBracket = false;

    if (e.keyCode === 8 || e.keyCode === 46) {
      // Check if the backspace (8) or delete (46) key is pressed
      await deletevariable(editorNode, e.preventDefault);
    }

    if (e.key === '{' && prevKey === '{') {
      IsBracket = true;

      const customEvent = new MouseEvent('contextmenu', {
        bubbles: true,
        cancelable: true,
        view: window,
        button: 2,
        buttons: 2,
        clientX: getCarretCoordiantes(editorNode).x + 5,
        clientY:
          getCarretCoordiantes(editorNode).height +
          getCarretCoordiantes(editorNode).y
      });

      e.target.dispatchEvent(customEvent);
    } else {
      const ignoredKeyCodes = [37, 38, 39, 40]; //   Arrow keys
      if (ignoredKeyCodes.includes(e.keyCode)) return;

      handleCursorIsInsideVariable(editorNode);
    }

    if (e.keyCode !== 16) prevKey = e.key;
  };

  const deleteLastCharacter = (editor: SEditorType) => {
    const currentSelection = editor.selection.getNode();
    if (currentSelection && currentSelection.tagName === 'P') {
      editor.execCommand('Delete');
    }
  };

  const initVariableOptions: Partial<
    Omit<EditorOptions, 'selector' | 'target'>
  > = {
    setup: (editor: SEditorType) => {
      editor.on('keydown', (e: KeyboardEvent) => handleKeyDown(e, editor));

      editor.on('click', () => {
        if (prevKey === '{') {
          editor?.execCommand('mceInsertContent', false, `{`);
          prevKey = '';
        }
      });

      editor.ui.registry.addContextMenu('variableMenu', {
        update: element => {
          const varFunc = IsBracket ? insertVariable : editVariable;

          const items = variableOptions?.map((variable: IEditorVariables) => ({
            text: variable.title,
            onAction: () => {
              deleteLastCharacter(editor);
              varFunc(variable?.value, editor);
            }
          }));

          if (
            (element.nodeName === 'SPAN' &&
              element.classList.contains('mce-mergetag')) ||
            IsBracket
          ) {
            IsBracket = false;
            return items;
          }

          return [];
        }
      });
    }
  };

  return {
    variables,
    initVariableOptions,

    setVariables,
    handleVariables
  };
};
