import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';

import { useStyles } from './style';
import SLabel from '../../sui/slabel';
import { AddLabel } from './components';
import SHighlightText from '../../shighlight-text';
import { SAOptionProps, SAutocompleteProps } from './interface';

const filter = createFilterOptions();

const SAutocomplete = ({
  label,
  values,
  options,
  loading,
  required,
  multiple,
  labelClass,
  placeholder,

  onChange
}: SAutocompleteProps) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      {Boolean(label) && (
        <SLabel
          title={label}
          variant="body2"
          required={required}
          className={clsx(labelClass, classes.title)}
        />
      )}
      <Autocomplete
        onChange={(_, newValue) => onChange(newValue as SAOptionProps)}
        filterOptions={(allOptions, params) => {
          const filtered = filter(allOptions, params);

          const array = filtered?.map((option: SAOptionProps) => ({
            ...option,
            label: (
              <SHighlightText
                color="inherit"
                value={option.value}
                searchQuery={params.inputValue}
              />
            )
          }));

          if (params.inputValue !== '') {
            array.push({
              inputValue: params.inputValue,
              label: AddLabel(params.inputValue, Boolean(filtered?.length)),
              id: uuidv4(),
              value: params.inputValue
            });
          }

          return array;
        }}
        getOptionLabel={option => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option?.value || '';
        }}
        renderOption={option => option.label}
        renderTags={() => null}
        multiple={multiple}
        handleHomeEndKeys
        loading={loading}
        options={options}
        disableClearable
        value={values}
        selectOnFocus
        clearOnBlur
        freeSolo
        classes={{
          popper: classes.popper
        }}
        renderInput={params => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
    </Box>
  );
};

export default SAutocomplete;
