import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { SelectProps } from '../index';
import { SOption } from '../../types';

export const useMultiSelect = ({
  min,
  max,
  options = [],
  placeholder = '',
  defaultOptions,

  onChange
}: Partial<SelectProps>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState(defaultOptions || []);
  const [anchor, setAnchor] = useState(null);
  const [data, setData] = useState(options);

  const getTitle = useMemo(() => {
    onChange(selected);

    if (selected.length === 0) return placeholder;
    if (selected.length === 1)
      return `${selected[0]?.label || selected[0].value}`;
    return `${selected.length} Selected`;
  }, [selected, placeholder, onChange]);

  const handleSelected = (option: SOption) => {
    const selectedOptions = selected.some((s: SOption) => s.id === option.id)
      ? selected.filter((s: SOption) => s.id !== option.id)
      : [...selected, option];

    let message = '';
    if (min && selectedOptions.length < min)
      message = `Select at least ${min} option(s)`;
    if (max && selectedOptions.length > max)
      message = `Select at most ${max} option(s)`;

    if (message) {
      enqueueSnackbar(message, {
        variant: 'warning'
      });

      return;
    }

    setSelected(selectedOptions);
  };

  const handleSearch = (value: string) => {
    if (value)
      return setData(
        options?.filter(t =>
          t?.value?.toString().toLowerCase().includes(value.toLowerCase())
        )
      );

    return setData(options);
  };

  return {
    getTitle,
    selected,
    anchor,
    data,

    handleSelected,
    handleSearch,
    setSelected,
    setAnchor,
    onChange,
    setData
  };
};
