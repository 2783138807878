import { SortType } from '@setvi/shared/enums';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getSuggestedContacts } from 'Services';
import { Contact } from 'Services/Query/Contacts/Types';

interface useSuggestedContactsProps {
  search: string;
}

const TOTAL_SUUGESTED_CONTACTS = 5;
export const useSuggestedContacts = ({ search }: useSuggestedContactsProps) => {
  const queryInput = useMemo(
    () => ({
      search,
      count: TOTAL_SUUGESTED_CONTACTS,
      sortBy: 'Email',
      orderBy: SortType.ASC
    }),
    [search]
  );

  const {
    data: suggestedContacts,
    isLoading,
    isFetching
  } = useQuery(getSuggestedContacts(queryInput));

  return {
    suggestedContacts: suggestedContacts?.Data?.Contacts as Contact[],
    isLoading: isLoading || isFetching
  };
};
