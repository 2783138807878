import { useCallback, useMemo, useState, MouseEvent, ChangeEvent } from 'react';
import { Resource } from '@setvi/shared/interfaces';
import {
  Box,
  Typography,
  Checkbox,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { useStyles } from '../../list/style';
import { Overlay } from '../overlay';
import SETVIImage from '../../../setvi-image';
import SText from '../../../sui/stext';
import { GetResourceTypeIcon } from '../../../../utils';
import { SDropdownMenu } from '../../..';
import { useSelectPagesDropdown } from '../../hooks/useSelectPagesDropdown';
import { resourceView } from '../../../../utils/viewer';
import { ResourceType } from '../../../../enums';

interface ItemProps {
  isStatic?: boolean;
  resource: Resource;
  onSelect: (checked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  isChecked: boolean;
}

export const Item = ({
  isStatic,
  resource,
  onSelect,
  onSelectPages,
  isChecked
}: ItemProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSelectAllPages = (res: Resource) => onSelect(!isChecked, [res]);

  const { menuItems } = useSelectPagesDropdown({
    resource,
    isChecked,
    onSelectPages,
    onSelectAllPages: handleSelectAllPages
  });

  const onThumbnailClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      resourceView({
        name: resource.Name,
        resourceTypeId: resource.ResourceTypeID,
        resourceId: resource.ResourceID
      });
    },
    [resource]
  );

  const selectDisabled = useMemo(
    () => !resource.IsShareable && !isStatic,
    [resource, isStatic]
  );

  const handleSelect = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      await onSelect(e.target.checked, [resource]);
      setLoading(false);
    },
    [onSelect, resource]
  );

  const hasSubmenu = [
    ResourceType.Presentation,
    ResourceType.PdfDocument
  ].includes(resource?.ResourceTypeID);

  const checkbox = hasSubmenu ? (
    <SDropdownMenu menuItems={menuItems}>
      <Checkbox disabled={selectDisabled} color="primary" checked={isChecked} />
    </SDropdownMenu>
  ) : (
    <Checkbox
      disabled={selectDisabled}
      color="primary"
      checked={isChecked}
      onChange={handleSelect}
    />
  );

  return (
    <Box className={classes.innerWrap} mb={3}>
      <Box className={classes.imageContainer} onClick={onThumbnailClick}>
        <SETVIImage
          className={classes.panelImage}
          src={resource.ThumbURLWithSas || resource.ThumbURL}
          alt="Resource"
          loading="lazy"
        />
        <Overlay />
      </Box>

      <Box flex={1} ml={2}>
        <SText
          title={resource.Name}
          className={classes.itemName}
          weight="medium"
          size="sm"
          maxLines={2}
        />
        <Box display="flex" alignItems="center">
          <img
            src={GetResourceTypeIcon(resource.ResourceTypeID)}
            className={classes.resourceIcon}
          />
          <Typography variant="subtitle2" color="textSecondary">
            {resource.ResourceTypeName}
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <Box style={{ padding: 12 }}>
          <CircularProgress color="primary" size={20} thickness={4.5} />
        </Box>
      ) : (
        <Tooltip
          arrow
          placement="top"
          title="The resource is not shareable."
          disableHoverListener={!selectDisabled}>
          <Box>{checkbox}</Box>
        </Tooltip>
      )}
    </Box>
  );
};
