import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { ComposeEmailProvider } from 'Providers/ComposeEmail';
import { NylasProvider } from 'Providers/Nylas';
import { UserInfoDialogProvider } from 'Providers/UserInfoDialogProvider';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { useSidebar } from './Sidebar/useSidebar';
import { getPageTitle } from './utils/pageTitle';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
  }
}));

const MainContainer = ({ children }: any) => (
  <NylasProvider>
    <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
      <ComposeEmailProvider>
        <UserInfoDialogProvider>{children}</UserInfoDialogProvider>
      </ComposeEmailProvider>
    </IntercomProvider>
  </NylasProvider>
);

export const Main = () => {
  const classes = useStyles();
  const location = useLocation();
  const { expandedSidebarMenuItem, layout, handleSetExpandedMenu } =
    useSidebar();

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  if (!layout)
    return (
      <MainContainer>
        <Outlet />
      </MainContainer>
    );

  return (
    <MainContainer>
      <div className={classes.root}>
        <CssBaseline />
        <Sidebar
          expandedSidebarMenuItem={expandedSidebarMenuItem}
          setExpandedSidebarMenuItem={handleSetExpandedMenu}
          collapsedDrawer
        />
        <main className={classes.container}>
          <Topbar />
          <Box className={classes.pageContainer}>
            <Outlet />
          </Box>
        </main>
      </div>
    </MainContainer>
  );
};
