import styled from 'styled-components';

import { SPositionStyleProps } from '../../interfaces';

export const SCenterAbsoluteWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SFloatRight = styled.div<SPositionStyleProps>`
  position: absolute;
  pointer-events: none;
  top: ${props => props?.top || '10px'};
  right: ${props => props?.right || '15px'};
`;
