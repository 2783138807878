import { useEffect, useState } from 'react';

import { SelectProps } from '../index';
import { SOption } from '../../types';

export const useSingleSelect = ({
  options = [],
  defaultOption,

  onChange
}: Partial<SelectProps>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState(options);
  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState<SOption | null>(
    defaultOption || null
  );

  const handleSelect = (option: SOption) => {
    const newOption = selected?.id === option?.id ? null : option;

    onChange(newOption);
    setSelected(newOption);
    setAnchor(null);
  };

  useEffect(() => {
    setData(
      options?.filter(t =>
        t?.value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [options, searchQuery]);

  return {
    data,
    anchor,
    selected,
    searchQuery,

    setData,
    setAnchor,
    setSearchQuery,
    setSelected,
    handleSelect
  };
};
