import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, TableCell } from '@material-ui/core';

export const StyledTableCell = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
    borderBottom: 'none'
  }
}))(TableCell);

export const FileLabel = withStyles(({ spacing, breakpoints }) => ({
  root: {
    marginLeft: spacing(2),
    [breakpoints.down('md')]: {
      display: 'none'
    }
  }
}))(Typography);

export const TableHeadCell = withStyles(({ palette, spacing }) => ({
  root: {
    padding: `${spacing(2)}px ${spacing(1)}px`,
    backgroundColor: palette.common.white
  }
}))(TableCell);

export const useTableStyles = makeStyles(theme => ({
  tableImage: {
    height: 40,
    width: 52,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[200]}`
  },
  title: {
    fontSize: 13,
    fontWeight: 600
  },
  subtitle: {
    fontSize: 12,
    color: '#696974'
  },
  tableFileIcon: {
    height: 27,
    marginRight: theme.spacing(2)
  },
  selectedRow: {
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.success.light}0f`,
      '& td, & th': {
        borderTop: `2px solid ${theme.palette.success.light}`,
        borderBottom: `2px solid ${theme.palette.success.light}`
      },
      '& td': {
        '&:first-child': {
          borderLeft: `2px solid ${theme.palette.success.light}`,
          borderRadius: '15px 0 0 15px'
        },
        '&:last-child': {
          borderRight: `2px solid ${theme.palette.success.light}`,
          borderRadius: '0 15px 15px 0'
        }
      },
      '&:hover': {
        backgroundColor: `${theme.palette.success.light}0f`
      }
    },
    '&:hover': {
      '& td': {
        '&:first-child': {
          borderRadius: '15px 0 0 15px'
        },
        '&:last-child': {
          borderRadius: '0 15px 15px 0'
        }
      }
    }
  },
  draggableRow: {
    cursor: 'grab'
  },
  hideOverflow: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 110
    }
  },
  modalResourceName: {
    [theme.breakpoints.down('sm')]: {
      width: 60
    }
  }
}));
