import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  Tooltip,
  withStyles
} from '@material-ui/core';

export interface SCheckboxProps extends CheckboxProps {
  tooltip?: string;
  disableTooltip?: boolean;
}

const Checkbox = withStyles({
  root: {
    padding: '0 !important'
  }
})(MUICheckbox);

export const SCheckbox = ({
  tooltip,
  disableTooltip,
  color = 'primary',
  ...rest
}: SCheckboxProps) => (
  <Tooltip title={tooltip || ''} disableHoverListener={disableTooltip} arrow>
    <span>
      <Checkbox
        color={color}
        {...rest}
        onClick={e => {
          e.stopPropagation();
          rest.onClick?.(e);
        }}
      />
    </span>
  </Tooltip>
);
