import { useCallback, useState } from 'react';
import { makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ResourceCategoryClass } from '@setvi/shared/interfaces';
import { ROUTES } from 'enumsV2';
import { Breadcrumb } from 'Interfaces';
import Expander from './Expander';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbsList from './BreadcrumbsList';

interface IResourceBreadcrumbs {
  breadcrumbs: Breadcrumb[];
  maxItems?: number;
  truncationLinkTextLimit?: number;
  additionalOnClick?: (data?: any) => void;
  showAll?: boolean;
  separator?: string;
  className?: string;
}

const useBreadcrumbStyles = makeStyles<Theme, { isExpand?: boolean }>({
  container: {
    display: 'flex',
    padding: 0,
    margin: 0,
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: ({ isExpand }) => (isExpand ? 'wrap' : 'nowrap')
  },
  breadcrumbSM: {
    fontSize: '0.86em'
  },
  breadcrumb: {
    cursor: 'pointer'
  }
});

export const Separator = withStyles({
  root: {
    margin: '0 8px',
    fontSize: 12,
    color: '#92929D'
  }
})(Typography);

const ResourceBreadcrumbs = ({
  breadcrumbs,
  maxItems = 2,
  separator = '/',
  className,
  showAll = false,
  truncationLinkTextLimit,
  additionalOnClick
}: IResourceBreadcrumbs) => {
  const [isExpand, setIsExpand] = useState(true);
  const [enableContentHeight, setEnableContentHeight] = useState(showAll);
  const styles = useBreadcrumbStyles({ isExpand });
  const navigate = useNavigate();

  const classes = clsx(styles.container, className);

  const onBreadcrumbClick = (bc: Breadcrumb) => {
    const link =
      bc.ResourceCategoryClass === ResourceCategoryClass.RESOURCES
        ? ROUTES.RESOURCES
        : ROUTES.WORKSPACES_FILES;

    navigate(`${link}?categoryId=${bc.ID}`);
    additionalOnClick?.();
  };

  const handleInitExpand = useCallback(
    (initExpand: boolean) => setIsExpand(initExpand),
    []
  );

  const handleOnExpanderClick = () => {
    setIsExpand(true);
    setEnableContentHeight(true);
  };

  if (!isExpand) {
    const lastBc = breadcrumbs[breadcrumbs.length - 1];
    return (
      <BreadcrumbsList
        showAll={showAll}
        enableContentHeight={enableContentHeight}
        classes={classes}
        handleSetIsExpand={handleInitExpand}>
        {breadcrumbs.slice(0, maxItems - 1).map((bc, index) => {
          const haveSeparator = index !== maxItems - 2;
          return (
            <BreadcrumbItem
              key={bc.Name}
              name={bc.Name}
              textLimit={truncationLinkTextLimit}
              separator={separator}
              haveSeparator={haveSeparator}
              onClick={() => onBreadcrumbClick(bc)}
            />
          );
        })}
        <Expander onClick={handleOnExpanderClick} separator={separator} />
        <BreadcrumbItem
          name={lastBc.Name}
          textLimit={truncationLinkTextLimit}
          separator={separator}
          haveSeparator={false}
          onClick={() => onBreadcrumbClick(lastBc)}
        />
      </BreadcrumbsList>
    );
  }

  return (
    <BreadcrumbsList
      showAll={showAll}
      enableContentHeight={enableContentHeight}
      classes={classes}
      handleSetIsExpand={handleInitExpand}>
      {breadcrumbs.map((bc, index) => (
        <BreadcrumbItem
          key={bc.Name}
          name={bc.Name}
          textLimit={truncationLinkTextLimit}
          separator={separator}
          haveSeparator={index !== breadcrumbs.length - 1}
          onClick={() => onBreadcrumbClick(bc)}
        />
      ))}
    </BreadcrumbsList>
  );
};

export default ResourceBreadcrumbs;
