import { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { SortType } from '@setvi/shared/enums';
import { getPresentationsQuery } from '@setvi/shared/services';

export const useContent = () => {
  const [searchString, setSearchString] = useState(null);
  const [after, setAfter] = useState(1);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const queryVariables = useMemo(
    () => ({
      PageNumber: after,
      PageSize: 999,
      SortBy: 'DateAdded',
      OrderBy: SortType.DESC,
      SearchTerm: !searchString ? undefined : searchString
    }),
    [after, searchString]
  );

  const { data, isLoading: loading } = useQuery(
    getPresentationsQuery(queryVariables)
  );

  const fetchMorePresentations = useCallback(() => {
    setAfter(prev => prev + 1);
  }, []);

  const searchPresentations = useCallback(
    (value: string) => {
      // set variables and loader
      setIsNextPageLoading(true);
      setSearchString(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    data,
    loading,
    searchPresentations,
    searchString,
    isNextPageLoading,
    fetchMorePresentations
  };
};
