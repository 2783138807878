import { makeStyles } from '@material-ui/core';
import { DRAWER_WIDTH } from '@setvi/shared/components/sdrawer/base';

export const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 1299,
    maxHeight: 600
  },
  containerMax: {
    backgroundColor: theme.palette.common.white,
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(4),
    maxWidth: 690,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  containerMin: { position: 'fixed', right: 0, maxWidth: 480, width: '100%' },
  marginContainerPinned: {
    right: DRAWER_WIDTH + theme.spacing(4)
  },
  editor: {
    '& .tox-tinymce': {
      borderRadius: 0,
      border: 'none',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
  }
}));
