import { useDialog } from '@setvi/shared/providers/dialog';
import { FC, useCallback, useState } from 'react';
import { SBase } from '@setvi/shared/components';
import PreviewLink from 'Components/Shared/Resources/Dialogs/GetLink/CreateLink/PreviewLink';
import { CreateLinkForm } from 'Components/Shared/Resources/Dialogs/GetLink/CreateLink/Form';

interface IGetLink {
  resourceId: number;
}

export const GetLink: FC<IGetLink> = ({ resourceId }) => {
  const [linkUrl, setLinkUrl] = useState<string>('');
  const { closeDialog } = useDialog();

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <SBase
      onClick={e => e.stopPropagation()}
      width={515}
      header={{
        title: 'Get Link',
        description:
          'Please say a tracking name to track if this content has been viewed in outbox.',
        onClose
      }}>
      {linkUrl ? (
        <PreviewLink linkName={linkUrl} onClose={onClose} />
      ) : (
        <CreateLinkForm
          resourceId={resourceId}
          onLinkGenerated={link => setLinkUrl(link)}
          onClose={onClose}
        />
      )}
    </SBase>
  );
};
