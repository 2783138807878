import styled from 'styled-components';

import { distances } from '../../styles';

export const SDatepickerInput = styled.input`
  background: transparent;
  border: 1px solid #dcdcde;
  height: 48px;
  margin-bottom: ${distances.px.large};
  border-radius: 8px;
  width: 100% !important;
  cursor: pointer;
  text-indent: 10px;

  ::placeholder {
    color: #989fb0;
  }

  :focus {
    outline: 2px solid #2196f3;
    cursor: text;
  }
`;
