import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const HeaderContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing(2)}px ${spacing(3)}px`,
    width: '100%',
    height: '100%'
  }
}))(Box);

export const useHeaderStyles = makeStyles(theme => ({
  container: {
    bottom: 0,
    position: 'fixed',
    width: 450,
    backgroundColor: '#44444F',
    borderRadius: '12px 12px 0px 0px'
  },
  maximizedContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px 0px`
  }
}));
