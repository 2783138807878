import { Box, makeStyles } from '@material-ui/core';
import { TimeLineItem } from './time-line-item';
import { TimelineItemBase } from '..';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

interface TimeLineItemsProps<T extends TimelineItemBase> {
  items: T[];
  renderLeft?: (item: T) => JSX.Element;
  renderRight?: (item: T) => JSX.Element;
}

export function TimeLineItems<T extends TimelineItemBase>({
  items,
  renderLeft,
  renderRight
}: TimeLineItemsProps<T>) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {items.map(item => (
        <TimeLineItem
          key={item.id}
          item={item}
          renderLeft={renderLeft}
          renderRight={renderRight}
        />
      ))}
    </Box>
  );
}
