import { Box } from '@material-ui/core';

import { PlusIcon } from '../../../../sicons';
import SText from '../../../../sui/stext';

const AddLabel = (label: string, hasOptions: boolean) => (
  <Box width="100%" borderTop={hasOptions ? '1px solid #E9E9E9' : 'none'}>
    <Box
      gridGap={4}
      display="flex"
      alignItems="center"
      marginTop={hasOptions ? 2 : 0}
      paddingY={hasOptions ? 0 : 2}>
      <PlusIcon
        color="primary"
        fontSize="small"
        style={{
          transform: 'scale(0.5)'
        }}
      />
      <SText color="primary" weight="bold">
        Create
      </SText>
      {label}
    </Box>
  </Box>
);

export default AddLabel;
