export enum DrawerContent {
  webLink = 'webLink',
  snippet = 'snippet',
  favorites = 'favorites',
  resources = 'resources',
  uploadFile = 'uploadFile',
  myResources = 'myResources',
  reviewLinks = 'reviewLinks',
  presentations = 'presentations',
  templates = 'templates',
  resourcesNavigation = 'resourcesNavigation',
  contactsNavigation = 'contactsNavigation',
  groups = 'groups',
  crmLeads = 'crmLeads',
  contacts = 'contacts',
  recentContacts = 'recentContacts',
  crmAccounts = 'crmAccounts',
  requiredReading = 'static'
}

export enum SortType {
  ASC = 'asc',
  DESC = 'desc'
}

export enum RolesEnum {
  ADMIN = 'Admin',
  STANDARD_USER = 'Standard user',
  SUB_ADMIN = 'Sub Admin'
}

export enum PresentationViewDevice {
  Unknown = 0,
  Desktop = 1,
  Tablet = 2,
  Mobile = 3
}
