import { RESOURCE_TYPE } from '@setvi/shared/enums';
import { Box } from '@material-ui/core';
import { Resource } from '@setvi/shared/interfaces';
import { useMemo } from 'react';
import { useResourceList } from './useResourceList';
import { AlgoliaSearchResults } from '../algolia-search-results';
import { List } from '../components/resource-list';
import SText from '../../sui/stext';

interface ResourceListProps {
  resourceType?: RESOURCE_TYPE;
  categoryId?: number;
  onSelect: (isChecked: boolean, resources: any) => void;
  onSelectPages?: (resource: Resource) => void;
  searchTerm?: string;
  sasToken?: string;
  algoliaKey?: string;
  selectedResources?: Resource[];
  onEmpty?: () => void;
}

const rowKeyGetter = ({ ID }: any) => ID;

export const ResourceList = ({
  resourceType = RESOURCE_TYPE.ALL_RESOURCES,
  categoryId,
  onSelect,
  onSelectPages,
  searchTerm,
  sasToken,
  algoliaKey,
  selectedResources = [],
  onEmpty
}: ResourceListProps) => {
  const useAlgoliaSearch =
    (resourceType === RESOURCE_TYPE.ALL_RESOURCES ||
      resourceType === RESOURCE_TYPE.USER_RESOURCES ||
      resourceType === RESOURCE_TYPE.REQURIED_READING) &&
    !!searchTerm.length;
  const { resources, fetchMore, isLoading, isFetching } = useResourceList({
    categoryId,
    resourceType,
    onEmpty,
    search: searchTerm,
    useAlgoliaSearch
  });

  const isEmpty = !resources.length;
  const loading = isLoading || isFetching;

  const emptyStateInfo = useMemo(() => {
    if (resourceType === RESOURCE_TYPE.FAVORITE_RESOURCES) {
      return {
        imageSrc: '/images/no-favorites.png',
        title: 'No Favorites available.'
      };
    }

    return {
      imageSrc: '/images/no-resources.svg',
      title: 'No resources available.',
      subTitle: 'You have no resources in this category'
    };
  }, [resourceType]);

  if (useAlgoliaSearch)
    return (
      <AlgoliaSearchResults
        onSelect={onSelect}
        rowKeyGetter={resource => `${resource.ID}-${resource.CategoryID}`}
        searchTerm={searchTerm}
        sasToken={sasToken}
        algoliaKey={algoliaKey}
        selectedResources={selectedResources}
        resourceType={resourceType}
        onSelectPages={onSelectPages}
      />
    );

  return (
    <Box p={2}>
      {!isEmpty && (
        <Box mb={2}>
          <SText weight="medium">Resources</SText>
        </Box>
      )}
      <List
        resources={resources}
        onSelect={onSelect}
        selectedResources={selectedResources}
        fetchMore={fetchMore}
        rowKeyGetter={rowKeyGetter}
        loading={loading}
        isEmpty={isEmpty}
        emptyStateInfo={emptyStateInfo}
        onSelectPages={onSelectPages}
      />
    </Box>
  );
};
