import { Box, makeStyles } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';

const useCreateLinkButtonStyles = makeStyles(() => ({
  button: {
    width: '49%',
    height: 50,
    fontSize: '1rem',
    fontWeight: 700
  }
}));

interface ICreateLinkButtons {
  confirmButtonText: string;
  disabled?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

export const CreateLinkButtons = ({
  confirmButtonText,
  disabled = false,
  loading,
  onClose,
  onConfirm
}: ICreateLinkButtons) => {
  const styles = useCreateLinkButtonStyles();

  return (
    <Box display="flex" justifyContent="space-between">
      <SButton
        color="inherit"
        className={styles.button}
        variant="outlined"
        onClick={onClose}>
        Close
      </SButton>
      <SButton
        disabled={disabled}
        loading={loading}
        onClick={() => onConfirm?.()}
        className={styles.button}
        type="submit">
        {confirmButtonText}
      </SButton>
    </Box>
  );
};
