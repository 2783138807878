import { makeStyles } from '@material-ui/core/styles';

import { distances } from '@setvi/shared/styles';

export const useStyles = makeStyles(() => ({
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  }
}));
