import { useSearchParams } from 'react-router-dom';
import { SortType } from '../../enums';

interface useSortProps {
  defaultSortBy?: string;
  defaultOrderBy?: SortType;
}

export const useSort = ({
  defaultSortBy = '',
  defaultOrderBy = SortType.ASC
}: useSortProps = {}) => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({ sortBy: defaultSortBy, orderBy: defaultOrderBy })
  );
  const sortBy = searchParams.get('sortBy');
  const orderBy = searchParams.get('orderBy') as SortType;

  const handleSortChange = (newSortBy: string) => {
    const params = {
      sortBy: newSortBy,
      orderBy:
        sortBy === newSortBy && orderBy === SortType.DESC
          ? SortType.ASC
          : SortType.DESC
    };

    setSearchParams(new URLSearchParams(params));
  };

  return {
    sort: {
      sortBy,
      orderBy
    },
    handleSortChange
  };
};
