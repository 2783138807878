import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 20px;
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
