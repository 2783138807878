import { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from '@setvi/shared/interfaces';

interface UseReviewLinksProps {
  links: Array<Link>;
  openLinkId?: string;
  cancelLinkId?: () => void;
  handleClose: () => void;
  handleRemoveLink(link?: Link): void;
}

export const useReviewLinks = ({
  links,
  openLinkId,
  cancelLinkId,
  handleClose,
  handleRemoveLink
}: UseReviewLinksProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [link, setLink] = useState<Link>(null);
  const [selectedResourcesIds, setSelectedResourcesIds] = useState([]);

  const selectedResources = useMemo(() => link?.Item?.Items, [link]);

  const handleEdit = (item: Link) => {
    setLink(item);
    setIsExpanded(true);
  };

  const handleBack = () => setIsExpanded(false);

  const handleRemove = useCallback(
    (removeLink?: Link) => {
      // If this is the last link
      if (links.length < 2) {
        handleRemoveLink(removeLink);
        handleClose();
      }
      handleRemoveLink(removeLink);
    },
    [handleRemoveLink, handleClose, links]
  );

  useEffect(() => {
    if (openLinkId) {
      setLink(links.find(addedLink => addedLink?.Placeholder === openLinkId));
      setIsExpanded(true);
      cancelLinkId?.();
    }
  }, [openLinkId, cancelLinkId, links]);

  // Close edit when link is deleted from editor
  useEffect(() => {
    if (isExpanded) {
      if (
        links.findIndex(
          addedLink => addedLink.Placeholder === link.Placeholder
        ) === -1
      ) {
        links.length ? setIsExpanded(false) : handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  return {
    isExpanded,
    setIsExpanded,
    link,
    setLink,
    selectedResourcesIds,
    setSelectedResourcesIds,
    selectedResources,
    handleEdit,
    handleBack,
    handleRemove
  };
};
