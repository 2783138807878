import { Link } from '@setvi/shared/interfaces';
import { useReviewLinks } from './useReviewLinks';
import { List } from './list';
import { Edit } from './edit';
import { Base } from '../base';

interface ReviewLinksProps {
  handleClose: () => void;
  links: Array<Link>;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
  openLinkId?: string;
  hideEditLink?: boolean;
  cancelLinkId?: () => void;
  handleBack?: () => void;
}

export const ReviewLinks = ({
  handleClose,
  links,
  handleInsert,
  handleRemoveLink,
  openLinkId,
  hideEditLink,
  cancelLinkId,
  handleBack: _handleBack
}: ReviewLinksProps) => {
  const {
    isExpanded,
    handleEdit,
    handleBack: handleBackFromEdit,
    link,
    handleRemove
  } = useReviewLinks({
    links,
    openLinkId,
    cancelLinkId,
    handleClose,
    handleRemoveLink
  });

  return (
    <Base>
      {isExpanded ? (
        <Edit
          link={link}
          handleBack={handleBackFromEdit}
          handleInsert={handleInsert}
          handleRemoveLink={handleRemove}
          handleClose={handleClose}
          hideEditLink={hideEditLink}
        />
      ) : (
        <List
          links={links}
          handleEdit={handleEdit}
          handleRemoveLink={handleRemove}
          handleClose={handleClose}
        />
      )}
    </Base>
  );
};
