import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { LinkObjectType } from '@setvi/shared/enums';
import { Link, Resource } from '@setvi/shared/interfaces';
import { getLinkItemFromResource } from '@setvi/shared/utils';
import { AcceptedFileTypes } from './accepted-files';

interface UseUploadProps {
  handleClose(): void;
  handleInsert?: (link: Link) => void;
  handleComplete?: () => void;
  handleCancel?: () => void;
}

export const useUpload = ({
  handleClose,
  handleInsert,
  handleComplete,
  handleCancel
}: UseUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [isCanceled, _setIsCanceled] = useState(false);

  // TODO: Fix this any
  const onDrop = async (acceptedFiles: any) => {
    setFiles(acceptedFiles);
    setUploading(true);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    acceptedFiles
  } = useDropzone({
    accept: AcceptedFileTypes,
    onDrop,
    disabled: uploading
  });

  useEffect(() => {
    if (fileRejections.length)
      enqueueSnackbar(
        acceptedFiles.length > 0
          ? 'Some file types are not supported.'
          : 'File type not supported.',
        { variant: 'error' }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections, acceptedFiles]);

  const onCompleteUpload = useCallback(
    (resources: Resource[]) => {
      if (resources.length) {
        const parsedResource = {
          Name: resources[0].Name,
          Type: LinkObjectType.Resources,
          Placeholder: uuidv4(),
          Item: {
            Items: resources.map(r => getLinkItemFromResource(r))
          }
        };
        handleInsert?.(parsedResource);
      }
      setUploading(false);
      handleComplete?.();
    },
    [handleComplete, handleInsert]
  );

  const resetFiles = () => {
    setFiles([]);
  };

  const onCancel = useCallback(() => {
    handleCancel ? handleCancel() : handleClose();
  }, [handleCancel, handleClose]);

  return {
    uploading,
    setUploading,
    files,
    resetFiles,
    onCompleteUpload,
    getRootProps,
    getInputProps,
    isDragActive,
    isCanceled,
    onCancel
  };
};
