import { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import App from 'App';
import RouterModal from 'Providers/RouterModal';
import { RealmProvider } from 'Providers/MongoRealmProvider';
import { GuestLayout, MainLayout, SetviGuestLayout } from 'Layouts';
import { NonAuthorizedRoutes } from './non-authorized';
import { AuthorizedRoutes } from './authorized';

// Lazy loading
import * as LazyPage from './lazy-loading/pages';
import { LazyLoader } from './lazy-loading/loader';

const lazyLoadElement = (component: JSX.Element) => (
  <Suspense fallback={<LazyLoader />}>{component}</Suspense>
);

const routes = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <AuthorizedRoutes />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: ROUTES.HOME,
                element: lazyLoadElement(<LazyPage.Home />)
              },
              {
                path: ROUTES.CAMPAIGNS,
                children: [
                  {
                    path: ROUTES.MY_CAMPAIGNS,
                    element: lazyLoadElement(<LazyPage.MyCampaignsPage />)
                  },
                  {
                    path: ROUTES.MY_CAMPAIGNS_CREATE,
                    element: lazyLoadElement(<LazyPage.CreateCampaign />)
                  },
                  {
                    path: ROUTES.MY_CAMPAIGNS_DETAIL,
                    element: lazyLoadElement(<LazyPage.Details />)
                  },
                  {
                    path: ROUTES.MY_CAMPAIGNS_DETAIL_FOLLOW_UP,
                    element: lazyLoadElement(<LazyPage.FollowUp />)
                  },
                  // COMPANY CAMPAIGNS //
                  {
                    path: ROUTES.COMPANY_CAMPAIGNS,
                    element: lazyLoadElement(<LazyPage.CompanyCampaign />)
                  }
                ]
              },
              {
                path: ROUTES.ANALYITICS,
                element: lazyLoadElement(<LazyPage.Analytics />)
              },
              {
                path: ROUTES.RESOURCES,
                element: lazyLoadElement(<LazyPage.Resources />)
              },
              {
                path: ROUTES.FAVORITES,
                element: lazyLoadElement(<LazyPage.Favorites />)
              },
              {
                path: ROUTES.WORKSPACES,
                children: [
                  {
                    path: ROUTES.WORKSPACES_FILES,
                    element: lazyLoadElement(<LazyPage.MyFiles />)
                  },
                  {
                    path: ROUTES.WORKSPACES_PRESENTATIONS,
                    element: lazyLoadElement(<LazyPage.MyPresentation />)
                  },
                  {
                    path: ROUTES.WORKSPACES_PRESENTATION_DETAILS,
                    element: lazyLoadElement(<LazyPage.PresentationDetails />)
                  },
                  {
                    path: ROUTES.WORKSPACES_TEMPLATE_DETAILS,
                    element: lazyLoadElement(<LazyPage.TemplateDetails />)
                  },
                  {
                    path: ROUTES.WORKSPACES_SNIPPETS,
                    element: lazyLoadElement(<LazyPage.WorkspacesSnippets />)
                  },
                  {
                    path: ROUTES.WORKSPACES_CONTACTS_GROUPS,
                    element: lazyLoadElement(<LazyPage.ContactsAndGroups />),
                    children: [
                      {
                        index: true,
                        element: lazyLoadElement(<LazyPage.Contacts />)
                      },
                      {
                        path: ROUTES.WORKSPACES_GROUPS,
                        element: lazyLoadElement(<LazyPage.Groups />)
                      }
                    ]
                  }
                ]
              },
              {
                path: ROUTES.EMAIL_QUEUE,
                element: lazyLoadElement(<LazyPage.EmailQueue />)
              },
              {
                path: ROUTES.OUTBOX,
                element: lazyLoadElement(<LazyPage.Outbox />),
                children: [
                  {
                    index: true,
                    element: lazyLoadElement(<LazyPage.Emails />)
                  },
                  {
                    path: ROUTES.OUTBOX_GET_LINK,
                    element: lazyLoadElement(<LazyPage.OutboxGetLink />)
                  },
                  {
                    path: ROUTES.OUTBOX_OUTLOOK,
                    element: lazyLoadElement(<LazyPage.Outlook />)
                  },
                  {
                    path: ROUTES.OUTBOX_CAMPAIGN,
                    element: lazyLoadElement(<LazyPage.OutboxMyCampaigns />)
                  }
                ]
              },
              {
                path: ROUTES.INTELLIGENCE_GLOBAL,
                element: lazyLoadElement(<LazyPage.IntelligenceGlobal />),
                children: [
                  {
                    path: ROUTES.INTELLIGENCE_GLOBAL_OVERVIEW,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceGlobalOverview />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_GLOBAL_INTERESTED,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceGlobalIntersted />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_GLOBAL_NOT_INTERESTED,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceGlobalNotInterested />
                    )
                  }
                ]
              },
              {
                path: ROUTES.INTELLIGENCE_DETAILS,
                element: lazyLoadElement(<LazyPage.CustomerDetails />),
                children: [
                  {
                    path: ROUTES.INTELLIGENCE_OVERVIEW,
                    element: lazyLoadElement(<LazyPage.IntelligenceOverview />)
                  },
                  {
                    path: ROUTES.INTELLIGENCE_TIMELINE,
                    element: lazyLoadElement(<LazyPage.IntelligenceTimeline />)
                  },
                  {
                    path: ROUTES.INTELLIGENCE_PRODUCTS,
                    element: lazyLoadElement(<LazyPage.IntelligenceProducts />)
                  },
                  {
                    path: ROUTES.INTELLIGENCE_TIMELINE,
                    element: lazyLoadElement(<LazyPage.IntelligenceTimeline />)
                  },
                  {
                    path: ROUTES.INTELLIGENCE_INTERESTED,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceInterested />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_NOT_INTERESTED,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNotInterested />
                    )
                  }
                ]
              },
              {
                path: ROUTES.INTELLIGENCE_NEW,
                element: lazyLoadElement(<LazyPage.IntelligenceNew />),
                children: [
                  {
                    index: true,
                    path: ROUTES.INTELLIGENCE_NEW_AR,
                    element: lazyLoadElement(<LazyPage.IntelligenceNewAR />)
                  },
                  {
                    index: true,
                    path: ROUTES.INTELLIGENCE_NEW_CROSS_SELL_OPPORTUNITY,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceCrossSellOpportunity />
                    )
                  },
                  {
                    index: true,
                    path: ROUTES.INTELLIGENCE_NEW_OPEN_ORDERS,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNewOpenOrders />
                    )
                  },
                  {
                    index: true,
                    path: ROUTES.INTELLIGENCE_NEW_STOPPED_PURCHASING,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceStoppedPurchasing />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_NEW_CUSTOMER,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNewCustomer />
                    ),
                    children: [
                      {
                        index: true,
                        element: lazyLoadElement(
                          <LazyPage.IntelligenceNewCustomerList />
                        )
                      }
                    ]
                  },
                  {
                    path: ROUTES.INTELLIGENCE_NEW_CATEGORY,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNewCategory />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_NEW_PRODUCTS,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNewProducts />
                    )
                  },
                  {
                    path: ROUTES.INTELLIGENCE_NEW_ALL_INTERESTED,
                    element: lazyLoadElement(
                      <LazyPage.IntelligenceNewAllInterested />
                    )
                  }
                ]
              },
              {
                path: ROUTES.OUTBOX_OUTLOOK_DETAILS,
                element: lazyLoadElement(<LazyPage.OutlookDetails />),
                children: [
                  {
                    index: true,
                    element: lazyLoadElement(<LazyPage.OutlookEmailDetails />)
                  },
                  {
                    path: ROUTES.OUTBOX_OUTLOOK_VIEWS,
                    element: lazyLoadElement(<LazyPage.OutlookViews />)
                  }
                ]
              },
              {
                path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE,
                element: lazyLoadElement(
                  <LazyPage.MultipleRecipientEmailDetails />
                ),
                children: [
                  {
                    path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE_OVERVIEW,
                    element: lazyLoadElement(<LazyPage.Overview />)
                  },
                  {
                    path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE_EMAIL_DETAILS,
                    element: lazyLoadElement(<LazyPage.EmailDetails />)
                  }
                ]
              },
              {
                path: ROUTES.OUTBOX_EMAIL_DETAILS_SINGLE,
                element: lazyLoadElement(
                  <LazyPage.SingleRecipientEmailDetails />
                )
              },
              // {/* ******* REPORTING ******** */}
              {
                path: ROUTES.REPORTING_DETAILS,
                element: <LazyPage.ReportingDetails />
              },
              // {/* ******* ADMIN ******** */}
              {
                path: ROUTES.ADMIN,
                children: [
                  {
                    path: ROUTES.ADMIN_CAMPAIGNS,
                    element: lazyLoadElement(<LazyPage.AdminCampaigns />),
                    children: [
                      {
                        index: true,
                        element: lazyLoadElement(
                          <LazyPage.AdminCampaignsTable />
                        )
                      },
                      {
                        path: ROUTES.ADMIN_CAMPAIGNS_DETAILS,
                        element: lazyLoadElement(
                          <LazyPage.AdminCampaignDetails />
                        ),
                        children: [
                          {
                            index: true,
                            element: lazyLoadElement(
                              <LazyPage.AdminCampaignDetailsContent />
                            )
                          },
                          {
                            path: ROUTES.ADMIN_CAMPAIGNS_DETAILS_RECIPIENTS,
                            element: lazyLoadElement(
                              <LazyPage.AdminCampaignDetailsRecipients />
                            )
                          }
                        ]
                      },
                      {
                        path: ROUTES.ADMIN_CAMPAIGNS_DETAILS_USER_CAMPAIGNS,
                        element: lazyLoadElement(<LazyPage.Details />)
                      },
                      {
                        path: ROUTES.ADMIN_CAMPAIGNS_CREATE,
                        element: lazyLoadElement(
                          <LazyPage.CreateAdminCampaign />
                        )
                      },
                      {
                        path: ROUTES.ADMIN_CAMPAIGNS_EDIT,
                        element: lazyLoadElement(
                          <LazyPage.UpdateAdminCampaign />
                        )
                      }
                    ]
                  },
                  {
                    path: ROUTES.ADMIN_RESOURCES,
                    element: lazyLoadElement(<LazyPage.AdminResources />),
                    children: [
                      {
                        path: ROUTES.ADMIN_RESOURCES_DETAILS,
                        element: lazyLoadElement(
                          <LazyPage.AdminCategoryDetails />
                        )
                      },
                      {
                        path: ROUTES.ADMIN_RESOURCES_CREATE_CATEGORY,
                        element: (
                          <RouterModal
                            route={ROUTES.ADMIN_RESOURCES_CREATE_CATEGORY}
                            fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                            {lazyLoadElement(<LazyPage.CreateCategory />)}
                          </RouterModal>
                        )
                      },
                      {
                        path: ROUTES.ADMIN_RESOURCES_EDIT_CATEGORY,
                        element: (
                          <RouterModal
                            route={ROUTES.ADMIN_RESOURCES_EDIT_CATEGORY}
                            fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                            {lazyLoadElement(<LazyPage.EditCategory />)}
                          </RouterModal>
                        )
                      },
                      {
                        path: ROUTES.ADMIN_RESOURCES_EDIT,
                        element: (
                          <RouterModal
                            route={ROUTES.ADMIN_RESOURCES_EDIT}
                            fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                            {lazyLoadElement(<LazyPage.EditResource />)}
                          </RouterModal>
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: ROUTES.PRODUCTS,
                element: (
                  <RealmProvider>
                    {lazyLoadElement(<LazyPage.Products />)}
                  </RealmProvider>
                )
              },
              {
                path: ROUTES.SETTINGS,
                element: lazyLoadElement(<LazyPage.Settings />)
              }
            ]
          }
        ]
      },
      {
        element: <NonAuthorizedRoutes />,
        children: [
          {
            element: window.location.origin.includes('middleby') ? (
              <GuestLayout />
            ) : (
              <SetviGuestLayout />
            ),
            children: [
              {
                path: ROUTES.LOGIN,
                element: lazyLoadElement(<LazyPage.Authenticate />)
              }
            ]
          }
        ]
      },
      {
        element: <Navigate to={ROUTES.HOME} replace />,
        path: ROUTES.NOT_FOUND
      }
    ]
  }
]);

export const AppRoutes = () => (
  <RouterProvider future={{ v7_startTransition: true }} router={routes} />
);
