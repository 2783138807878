import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { debounce } from 'lodash';
import { Close } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';

import { SearchIcon as Search } from '../../sicons/regular-icons';
import { SearchField, useStyles } from './style';

export interface SSearchInputProps {
  placeholder?: string;
  loading?: boolean;
  width?: number | string;
  searchedValue?: string;
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;

  onBack?: () => void;
  onChange(val: string): void;
}

export const SSearchInput = ({
  placeholder = 'Search...',
  width = 320,
  loading = false,
  searchedValue,
  defaultValue,
  className,
  style,

  onBack,
  onChange
}: SSearchInputProps) => {
  const [value, setValue] = useState(defaultValue || '');

  const classes = useStyles({ width });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((val: string) => onChange(val), 500),
    [onChange]
  );

  useEffect(
    () => {
      if (searchedValue !== undefined && value !== searchedValue) {
        // ! This line gives bug when use searchedValue and onChange in the same time
        // handleChange(searchedValue);
        setValue(searchedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );

  const handleInputClear = () => {
    setValue('');
    onChange('');
  };

  const icon = useMemo(() => {
    if (loading) return <CircularProgress size={17} color="inherit" />;
    if (value) return <Close />;
    return <Search />;
  }, [loading, value]);

  return (
    <SearchField
      placeholder={placeholder}
      className={clsx(classes.wrapper, className)}
      style={style}
      onChange={e => {
        handleChange(e.target.value);
        setValue(e.target.value);
      }}
      value={value}
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <IconButton size="small" disabled={!value} onClick={handleInputClear}>
            {icon}
          </IconButton>
        </InputAdornment>
      }
      startAdornment={
        onBack && (
          <InputAdornment position="start">
            <IconButton size="small" onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
