import { useCallback, useMemo } from 'react';

import { Link, Recipient, Resource } from '@setvi/shared/interfaces';
import { DrawerContent } from '@setvi/shared/enums/common';
import { RESOURCE_TYPE } from '@setvi/shared/enums/resources';

import { Content as RequiredReading } from './required-reading';
import { Content as Presentations } from './presentations';
import { Content as Templates } from './templates';
import { Content as Resources } from './resources';
import { Content as Snippets } from './snippets';
import { Content as WebLink } from './web-link';
import { Content as Upload } from './upload';
import { ContactsNavigation, SalesforceUser } from './contacts-navigation';
import { CrmLeads } from './crm/leads';
import { Groups } from './groups';
import { ReviewLinks } from './review-links';
import { Base } from './base';
import { List } from './list';
import { TContact } from './contacts/contact';
import { Contacts } from './contacts';
import { CrmAccounts } from './crm/accounts';
import { RecentContacts } from './contacts-recent';

interface SDrawerProps {
  sasToken?: string;
  links?: Array<Link>;
  openLinkId?: string;
  algoliaKey?: string;
  hideEditLink?: boolean;
  redirectTo?: DrawerContent | null;
  showContent?: DrawerContent | null;
  salesforceUser?: SalesforceUser;
  selectedContacts?: Recipient[];
  options?: DrawerContent[];
  staticResources?: Resource[];

  handleClose(): void;
  cancelLinkId?: () => void;
  handleRemoveLink?(link?: Link): void;
  insertMessage?(message: string): void;
  modifyRecipientList?: (contact: TContact) => void;
  modifyRecipientListWithListOfContacts?: (
    contacts: TContact[],
    add: boolean
  ) => void;
  setShowContent?: (content: DrawerContent | null) => void;
  handleInsert?(link: Link, currentLink?: Link): void;
}

const SDrawer = ({
  links,
  sasToken,
  algoliaKey,
  openLinkId,
  redirectTo = null,
  showContent,
  salesforceUser,
  selectedContacts,
  options,
  hideEditLink,
  staticResources,
  handleClose,
  cancelLinkId,
  handleInsert,
  insertMessage,
  modifyRecipientList,
  modifyRecipientListWithListOfContacts,
  setShowContent,
  handleRemoveLink
}: SDrawerProps) => {
  const goToMenu = useCallback(
    () => setShowContent?.(DrawerContent.resourcesNavigation),
    [setShowContent]
  );

  const handleBack = useMemo(
    () => (!redirectTo ? goToMenu : null),
    [goToMenu, redirectTo]
  );

  const onInsertLink = useCallback(
    (link: Link) => {
      handleInsert(link);
      !redirectTo && goToMenu();
    },
    [handleInsert, goToMenu, redirectTo]
  );

  const goToReviewLinks = useCallback(() => {
    setShowContent?.(DrawerContent.reviewLinks);
  }, [setShowContent]);

  const sections = useMemo(
    () => ({
      [DrawerContent.favorites]: (
        <Resources
          type={RESOURCE_TYPE.FAVORITE_RESOURCES}
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideEditLink={hideEditLink}
        />
      ),
      [DrawerContent.resources]: (
        <Resources
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideEditLink={hideEditLink}
          preselected={staticResources}
          type={
            options?.includes(DrawerContent.requiredReading)
              ? RESOURCE_TYPE.REQURIED_READING
              : RESOURCE_TYPE.ALL_RESOURCES
          }
        />
      ),
      [DrawerContent.myResources]: (
        <Resources
          type={RESOURCE_TYPE.USER_RESOURCES}
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideEditLink={hideEditLink}
        />
      ),
      [DrawerContent.requiredReading]: (
        <RequiredReading
          type={RESOURCE_TYPE.REQURIED_READING}
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideEditLink={hideEditLink}
          preselected={staticResources}
        />
      ),
      [DrawerContent.uploadFile]: (
        <Upload
          handleBack={handleBack}
          handleComplete={hideEditLink ? handleBack : links && goToReviewLinks}
          handleInsert={handleInsert}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.presentations]: (
        <Presentations
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.templates]: (
        <Templates
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.webLink]: (
        <WebLink
          handleBack={handleBack}
          handleInsert={onInsertLink}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.snippet]: (
        <Snippets
          handleBack={handleBack}
          insertMessage={insertMessage}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.contactsNavigation]: (
        <ContactsNavigation
          salesforceUser={salesforceUser}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.resourcesNavigation]: (
        <List
          linkCount={links?.length}
          setShowContent={setShowContent}
          handleClose={handleClose}
          options={options}
        />
      ),
      [DrawerContent.groups]: (
        <Groups
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
          selectedContacts={selectedContacts}
          modifyRecipientListWithListOfContacts={
            modifyRecipientListWithListOfContacts
          }
        />
      ),
      [DrawerContent.crmLeads]: (
        <CrmLeads
          salesforceUser={salesforceUser}
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.contacts]: (
        <Contacts
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.crmAccounts]: (
        <CrmAccounts
          salesforceUser={salesforceUser}
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.recentContacts]: (
        <RecentContacts
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      )
    }),
    [
      handleBack,
      onInsertLink,
      handleClose,
      hideEditLink,
      staticResources,
      links,
      goToReviewLinks,
      handleInsert,
      insertMessage,
      salesforceUser,
      setShowContent,
      options,
      modifyRecipientList,
      selectedContacts,
      modifyRecipientListWithListOfContacts
    ]
  );

  // Review links has separate base and header
  if (
    showContent === DrawerContent.reviewLinks ||
    redirectTo === DrawerContent.reviewLinks
  ) {
    return (
      <ReviewLinks
        handleBack={handleBack}
        links={links}
        handleInsert={handleInsert}
        handleRemoveLink={handleRemoveLink}
        openLinkId={openLinkId}
        cancelLinkId={cancelLinkId}
        handleClose={handleClose}
        hideEditLink={hideEditLink}
      />
    );
  }

  if (redirectTo) {
    return (
      <Base sasToken={sasToken} algoliaKey={algoliaKey}>
        {sections[redirectTo]}
      </Base>
    );
  }

  return (
    <Base sasToken={sasToken} algoliaKey={algoliaKey}>
      <>
        {!showContent ? (
          <List
            linkCount={links?.length}
            setShowContent={setShowContent}
            handleClose={handleClose}
            options={options}
          />
        ) : (
          sections[showContent]
        )}
      </>
    </Base>
  );
};

export default SDrawer;
