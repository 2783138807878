import { FC, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link } from '@setvi/shared/interfaces';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { getPreviewLink } from '@setvi/shared/utils';
import { Menu } from '../Menu';
import SText from '../../../sui/stext';

interface BackProps {
  handleBack: () => void;
  handleRemoveLink(link?: Link): void;
  link: Link;
}

const Container = withStyles(({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  }
}))(Box);

const useStyles = makeStyles({
  title: {
    fontWeight: 600
  }
});

export const Back: FC<BackProps> = ({ handleBack, handleRemoveLink, link }) => {
  const classes = useStyles();
  const onPreview = useCallback(
    () =>
      window
        .open(generateLinkViewerUrl(getPreviewLink(link)), '_blank')
        .focus(),
    [link]
  );
  const onDelete = useCallback(() => {
    handleRemoveLink(link);
    handleBack();
  }, [handleRemoveLink, handleBack, link]);

  return (
    <BaseSubheader
      handleBack={handleBack}
      customTitle={
        <Container>
          <SText
            showTooltip
            variant="body1"
            title={link?.Name}
            className={classes.title}
          />
          <Menu onPreview={onPreview} onDelete={onDelete} />
        </Container>
      }
    />
  );
};
