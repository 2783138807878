import { Box, makeStyles, withStyles } from '@material-ui/core';
import { distances } from '../../../../styles';

export const TableLabel = withStyles({
  root: {
    textAlign: ({ align }: any) => align || 'left'
  }
})(Box);

export const useStyles = makeStyles(({ palette }) => ({
  sortable: {
    cursor: 'pointer',
    '& > span': {
      marginRight: distances.xmedium
    },
    '& button': {
      opacity: 0,
      transition: 'opacity 0.3s ease'
    },
    '&:hover button': {
      opacity: 1
    }
  },
  checkbox: {
    padding: '0 !important'
  },
  required: {
    color: `${palette.error.main} !important`,
    marginLeft: 4
  },
  sortIcon: {
    transform: 'rotate(-90deg)',
    transition: 'all 0.3s ease'
  },
  tableContainer: {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      height: 20
    }
  },
  grow: {
    flexGrow: 1
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    maxWidth: '100%'
  },
  header: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rightSideHeaderWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  tableFlexOnLoading: {
    flexGrow: 1,
    position: 'relative'
  },
  clickableRow: {
    cursor: 'pointer'
  },
  emptyWrapper: {
    flex: 1,
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%'
  },
  sortIconWraper: {
    '& svg': {
      position: 'absolute',
      right: '-22px'
    }
  },
  tableHeader: {
    backgroundColor: '#FAFAFB'
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer'
    }
  }
}));
