import { useMemo, useState, useEffect, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import {
  getSnippetsQuery,
  getCompanySnippetsQuery
} from '../../../../services';
import { Snippet } from '../../../../interfaces';
import { SNIPPETS_TAB } from './index';

export const useContent = () => {
  const [currentTab, setCurrentTab] = useState(SNIPPETS_TAB.mySnippets);
  const [previewSnippet, setPreviewSnippet] = useState<Snippet>(null);
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [teamSnippets, setTeamSnippets] = useState<Snippet[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOnBack = useCallback(() => {
    setPreviewSnippet(null);
  }, [setPreviewSnippet]);

  const {
    data: mySnippets,
    isLoading: loading,
    refetch: mySnippetsRefetch
  } = useQuery(getSnippetsQuery());

  const { data: companySnippets, isLoading: companySnippetsLoading } = useQuery(
    getCompanySnippetsQuery()
  );

  useEffect(() => {
    setSnippets(
      mySnippets?.map((snippet: any) => ({
        id: snippet.Id,
        title: snippet.Name,
        message: snippet.Text,
        lastModified: snippet?.LastChanged
          ? moment(snippet.LastChanged).format('M/D/YYYY')
          : ''
      })) || []
    );
  }, [mySnippets]);

  useEffect(() => {
    setTeamSnippets(
      companySnippets?.map((snippet: any) => ({
        id: snippet.Id,
        title: snippet.Name,
        message: snippet.Text,
        lastModified: snippet?.ChangeDate
          ? moment(snippet.LastChanged).format('M/D/YYYY')
          : ''
      })) || []
    );
  }, [companySnippets]);

  const showSearch = useMemo(() => {
    if (currentTab === SNIPPETS_TAB.mySnippets && snippets?.length > 0)
      return true;

    if (currentTab === SNIPPETS_TAB.sharedSnippets && teamSnippets?.length > 0)
      return true;

    return false;
  }, [currentTab, snippets?.length, teamSnippets?.length]);

  return {
    showSearch,
    currentTab,
    setCurrentTab,
    previewSnippet,
    setPreviewSnippet,
    snippets: snippets?.filter((snippet: Snippet) =>
      snippet.title.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    mySnippetsRefetch,
    searchTerm,
    setSearchTerm,
    teamSnippets: teamSnippets?.filter((snippet: Snippet) =>
      snippet.title.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    loading: loading || companySnippetsLoading,
    handleOnBack
  };
};
