import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode, MouseEvent } from 'react';
import { useCustomSelectButtonStyles } from './style';
import { FillArrowDownIcon } from '../../../../sicons';
import SText from '../../../../sui/stext';

interface SSelectDetailsButtonProps {
  width?: number;
  title: string;
  titleHighligted?: boolean;
  label?: string;
  icon?: ReactNode;
  open?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const SSelectDetailsButton = ({
  title,
  titleHighligted,
  open,
  icon,
  label,
  onClick,
  width
}: SSelectDetailsButtonProps) => {
  const classes = useCustomSelectButtonStyles({ open, width });

  return (
    <Button className={classes.container} onClick={onClick}>
      {label && <Box className={classes.label}>{label}</Box>}
      <SText
        className={clsx({
          [classes.labelDetails]: true,
          [classes.labelHighlight]: titleHighligted
        })}
        title={title}
      />
      <Box className={classes.iconContainer}>
        {icon || <FillArrowDownIcon fontSize="small" />}
      </Box>
    </Button>
  );
};

export default SSelectDetailsButton;
