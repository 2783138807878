import { useCallback } from 'react';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { client } from 'filestack-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { RangeObject } from '../../interfaces';

export const useImages = () => {
  const { enqueueSnackbar } = useSnackbar();

  const fileStackUpload = useCallback(
    (file: File) =>
      client
        .init(process.env.FILESTACK_KEY)
        .upload(file, {
          onProgress: async ({ totalPercent }: { totalPercent: number }) =>
            totalPercent
        })
        .then(({ url }) => url)
        .catch(() => {
          enqueueSnackbar(
            'There was an error uploading your file, please try again later',
            {
              variant: 'error'
            }
          );
        }),
    [enqueueSnackbar]
  );

  const uploadLocalImage = (editor: TinyMCEEditor) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const id = 'image-placeholder';

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      const placholderHtml = `<span id=${id} class='image-placeholder' contentEditable="false">Loading...</span>`;
      await editor.execCommand('mceInsertContent', false, placholderHtml);

      const url = await fileStackUpload(file);

      editor?.dom?.getRoot()?.querySelector(`[id="${id}"]`)?.remove();

      if (!url) return;

      const imageHtml = `<img src=${url} width="300" height="200" alt="Editor image">`;
      await editor.execCommand('mceInsertContent', false, imageHtml);
      await editor.execCommand('mceInsertNewLine');
    };
  };

  const isCursorNextToImg = (range: RangeObject) => {
    const container = range?.startContainer;
    const offset = range?.startOffset;

    // Check the node before the cursor
    const prevNode = container?.childNodes[offset - 1] as Element;

    if (
      prevNode &&
      prevNode.nodeType === Node.ELEMENT_NODE &&
      prevNode.tagName === 'IMG'
    ) {
      return prevNode;
    }

    return false;
  };

  const deleteImage = async (
    e: {
      keyCode: number;
      preventDefault: () => void;
    },
    editor: TinyMCEEditor
  ) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      // Check if the backspace (8) or delete (46) key is pressed

      const selection = editor?.selection;
      const selectedNode = isCursorNextToImg(selection.getRng());
      if (!selectedNode) return;

      if (selectedNode?.hasAttribute('src')) {
        // If the selected node is an <a> tag, delete only the selected node
        const currentImage = selectedNode.getAttribute('src').split('/').pop();
        if (!currentImage) return;

        const newPolicy = {
          expiry: Math.floor(Date.now() / 1000) + 10 // Set expiration time (in this example, 1 hour from now)
          // Add other policy details as needed
        };

        // Convert the security policy to base64-encoded JSON
        const policyBase64 = btoa(JSON.stringify(newPolicy));

        // Generate the signature using your Filestack API secret
        const newSignature = CryptoJS.HmacSHA256(
          policyBase64,
          process.env.FILESTACK_HASH_KEY
        ).toString();

        await client
          .init(process.env.FILESTACK_KEY, {
            security: {
              policy: policyBase64,
              signature: newSignature
            }
          })
          .remove(currentImage)
          .then(() => {
            const range = selection.getRng();

            if (!selectedNode?.parentNode) return;
            range.setStartBefore(selectedNode);
            range.setEndAfter(selectedNode);
            selection.setRng(range);

            e.preventDefault();
          })
          .catch(() => {
            enqueueSnackbar(
              'There was an error removing your file, please try again later',
              {
                variant: 'error'
              }
            );
          });
      }
    }
  };

  return {
    uploadLocalImage,
    deleteImage
  };
};
