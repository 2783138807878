import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';

import { distances } from '@setvi/shared/styles';
import { FormikTextfield } from 'Components/Shared/FormikTextfield';

const useStyles = makeStyles({
  paddedBox: {
    padding: `${distances.px.medium} 0`
  },
  titleText: {
    fontSize: '1rem',
    fontWeight: 500
  }
});

interface userInformationFormInterface {
  disabled: boolean;
  label: string;
}

export const UserInformationForm: FC<userInformationFormInterface> = ({
  disabled,
  label
}) => {
  const classes = useStyles();

  const inputWithLabelList = [
    {
      name: 'FirstName',
      labelTitle: 'First Name',
      placeholder: 'Enter first name',
      required: true
    },
    {
      name: 'LastName',
      labelTitle: 'Last Name',
      placeholder: 'Enter last name',
      required: true
    },
    {
      name: 'PhoneNumber',
      labelTitle: 'Phone Number',
      placeholder: 'Enter phone number',
      required: window.location.origin.includes('middleby')
    }
  ];

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.paddedBox}>
          <Typography className={classes.titleText} variant="h6">
            {label}
          </Typography>
        </Box>
      </Grid>

      {inputWithLabelList.map(val => (
        <Grid item key={val.name}>
          <FormikTextfield
            name={val.name}
            label={val.labelTitle}
            placeholder={val.placeholder}
            required={val.required}
            disabled={disabled}
          />
        </Grid>
      ))}
    </>
  );
};
