import { SBase, SResourceChildrenList } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers/dialog';
import { useSelected } from '@setvi/shared/hooks';
import { Resource, ChildResource } from '@setvi/shared/interfaces';
import { ResourceType } from '@setvi/shared/enums';
import { Typography, withStyles } from '@material-ui/core';

const Description = withStyles({
  root: {
    color: '#92929E',
    fontSize: 14
  }
})(Typography);

const getType = (type: ResourceType) => {
  switch (type) {
    case ResourceType.Presentation:
      return 'Presentation';
    default:
      return 'PDF';
  }
};
interface ResourceChildrenProps {
  resource: Resource;
  confirm: (selected: ChildResource[]) => void;
}

export const ResourceChildren = ({
  resource,
  confirm
}: ResourceChildrenProps) => {
  const { closeDialog } = useDialog();
  const { selected, handleSelect } = useSelected<ChildResource>({
    dataKeyGetter: data => data.ItemID
  });

  const handleConfirm = () => {
    confirm(
      selected.map(i => ({
        ...i,
        ResourceTypeID: resource.ResourceTypeID,
        Name: `${resource.Name} (${i.SortOrder})`
      }))
    );
    closeDialog();
  };

  return (
    <SBase
      width="65vw"
      height="90vh"
      header={{
        title: `Select ${getType(resource.ResourceTypeID)} Pages`,
        description: (
          <Description>{`You can specific select the pages from ${getType(
            resource.ResourceTypeID
          )} to include from below thumbnails`}</Description>
        ),
        onClose: closeDialog
      }}
      footer={{
        onConfirm: handleConfirm,
        onClose: closeDialog,
        confirmLabel: `Add Selected Pages (${selected.length})`,
        disableConfirm: selected.length === 0
      }}>
      <SResourceChildrenList
        resource={resource}
        handleSelect={handleSelect}
        selected={selected}
      />
    </SBase>
  );
};
