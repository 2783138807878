import { useQuery } from '@tanstack/react-query';
import { getBreadcrumbsQuery } from '../../../services';

interface useBreadcrumbProps {
  categoryId?: string | number;
}

export const useBreadcrumb = ({ categoryId }: useBreadcrumbProps) => {
  const { data: breadcrumbs, isFetching: loading } = useQuery(
    getBreadcrumbsQuery(categoryId)
  );

  return { breadcrumbs, loading };
};
