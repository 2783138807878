import { Box, makeStyles } from '@material-ui/core';
import { TimelineItemBase } from '../..';

export const useStyles = makeStyles(({ spacing }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: spacing(5)
  },
  content: {
    display: 'flex',
    paddingLeft: spacing(9),
    height: 72,
    borderLeft: `1px solid #E2E2EA`,
    justifyContent: 'space-between',
    width: '100%'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    height: '100%',
    gap: spacing(3)
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50%',
    height: '100%',
    gap: spacing(2)
  },
  iconButton: {
    border: '1px solid #E2E2EA',
    fontSize: 16,
    padding: '4px'
  }
}));

const TimelineLine = () => (
  <Box
    style={{
      width: '50px',
      height: '1px',
      backgroundColor: '#E2E2EA',
      position: 'absolute',
      left: 32
    }}
  />
);

interface TimeLineItemProps<T extends TimelineItemBase> {
  item: T;
  renderLeft?: (item: T) => JSX.Element;
  renderRight?: (item: T) => JSX.Element;
}

export function TimeLineItem<T extends TimelineItemBase>({
  item,
  renderLeft,
  renderRight
}: TimeLineItemProps<T>) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <TimelineLine />
      <Box className={classes.content}>
        <Box className={classes.left}>{renderLeft?.(item)}</Box>
        <Box className={classes.right}>{renderRight?.(item)}</Box>
      </Box>
    </Box>
  );
}
