import { Checkbox, withStyles, makeStyles } from '@material-ui/core';

import { distances } from '../../../../../styles';

export const SCheckBox = withStyles(() => ({
  root: {
    padding: 0,
    color: '#44444F',
    marginLeft: distances.px.small
  }
}))(Checkbox);

export const useCheckBoxStyle = makeStyles(() => ({
  subText: {
    color: '#989FB0',
    fontSize: '14px',
    margin: '8px 0px'
  },
  required: {
    color: '#d72638'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& svg': {
      transform: 'scale(0.8)',

      '& path': {
        fill: '#B5B5BE'
      }
    }
  }
}));
