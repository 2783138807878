import { Box, Button, ButtonGroup, Checkbox } from '@material-ui/core';

import {
  SelectDetailsButton,
  SelectMenuHeader,
  SSelectDropdown
} from '@setvi/shared/components';
import { distances } from '@setvi/shared/styles';

import { BaseSelectProps, SOption } from '../types';
import { useMultiSelect } from './hook';

export interface SelectProps extends BaseSelectProps {
  onChange: (selected: SOption[]) => void;

  defaultOptions?: SOption[];
  min?: number;
  max?: number;
}

const MultiSelect = ({
  min,
  max,
  width,
  label = '',
  search = true,
  options = [],
  placeholder = '',
  headerTitle,
  clearButton = false,
  defaultOptions,

  onChange
}: SelectProps) => {
  const {
    getTitle,
    selected,
    anchor,
    data,

    handleSelected,
    handleSearch,
    setSelected,
    setAnchor
  } = useMultiSelect({
    min,
    max,
    options,
    placeholder,
    defaultOptions,

    onChange
  });

  return (
    <SSelectDropdown
      width={width}
      anchor={
        <SelectDetailsButton
          width={width}
          title={getTitle}
          label={label}
          titleHighligted={selected.length > 0}
          onClick={e => setAnchor(e?.currentTarget)}
          open={Boolean(anchor)}
        />
      }
      anchorElement={anchor}
      onClose={() => setAnchor(null)}
      content={
        <Box>
          <SelectMenuHeader
            search={search}
            title={headerTitle}
            clearButton={clearButton}
            handleClear={() => setSelected([])}
            handleChange={handleSearch}
          />
          <ButtonGroup
            fullWidth
            orientation="vertical"
            color="primary"
            style={{
              display: 'flex',
              marginTop: distances.px.small
            }}>
            {data.length > 0 ? (
              data.map(option => (
                <Button
                  key={option.id}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: `${distances.px.xsmall} 0 `
                  }}
                  variant="text"
                  color="default"
                  disabled={option.disabled}
                  onClick={() => {
                    handleSelected(option);
                  }}>
                  <Checkbox
                    color="primary"
                    style={{
                      padding: `${distances.px.xsmall} 0`,
                      margin: `0 5px 0 0`
                    }}
                    disabled={option.disabled}
                    checked={selected.some(s => s.id === option.id)}
                  />
                  {option?.htmlElement || option.value}
                </Button>
              ))
            ) : (
              <>No Results Found...</>
            )}
          </ButtonGroup>
        </Box>
      }
    />
  );
};

export default MultiSelect;
