import { createContext } from 'react';
import { DrawerContent } from '@setvi/shared/enums';
import { Recipient, Link } from '@setvi/shared/interfaces';

const initialValues = {
  sendTo: [] as Recipient[],
  textBody: '',
  subject: '',
  body: ''
};

export interface openComposeProps {
  linksData?: Link;
  drawerContent?: DrawerContent;
}

export type ComposeEmailContextType = {
  values: any;
  links: Array<Link>;

  open: ({ linksData, drawerContent }: openComposeProps) => void;
  close: () => void;
};

const contextDefaultValues: ComposeEmailContextType = {
  values: initialValues,
  links: [],

  // @ts-ignore
  open: () => {},
  close: () => {}
};

export const ComposeEmailContext =
  createContext<ComposeEmailContextType>(contextDefaultValues);
