import { RESOURCE_TYPE } from '@setvi/shared/enums/resources';
import { useEffect, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ResourceType } from '@setvi/shared/enums';
import { childResourcesMutation } from '@setvi/shared/services';
import {
  myResourceFilter,
  resourceFilter,
  useAlgolia
} from '../hooks/useAlgolia';

interface UseSearchResultsProps {
  searchTerm?: string;
  sasToken?: string;
  algoliaKey?: string;
  resourceType: RESOURCE_TYPE;
  onSelect: (isChecked: boolean, resources: any) => void;
}

const ResourcesWithChildren = [
  ResourceType.Presentation,
  ResourceType.KeyNote,
  ResourceType.PhotoAlbum
];

export const useSearchResults = ({
  searchTerm,
  sasToken,
  algoliaKey,
  resourceType,
  onSelect
}: UseSearchResultsProps) => {
  const {
    isLoading: isAlgoliaLoading,
    resources: algoliaResultResources,
    searchAlgoliaResources,
    loadNextAlgoliaResult,
    totalCount
  } = useAlgolia({
    sasToken,
    algoliaKey,
    filters:
      resourceType === RESOURCE_TYPE.ALL_RESOURCES ||
      resourceType === RESOURCE_TYPE.REQURIED_READING
        ? resourceFilter
        : myResourceFilter
  });

  const { mutateAsync } = useMutation(childResourcesMutation());

  const handleSearchQueryChange = useCallback(
    (query: string) => {
      searchAlgoliaResources(query);
    },
    [searchAlgoliaResources]
  );

  useEffect(() => {
    handleSearchQueryChange(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const loadNextPage = useCallback(
    () => loadNextAlgoliaResult(searchTerm),
    [loadNextAlgoliaResult, searchTerm]
  );

  const handleSelect = useCallback(
    async (isChecked: boolean, resources: any) => {
      // Skip api call if resource type doesn't have child resources
      if (ResourcesWithChildren.indexOf(resources[0].ResourceTypeID) === -1) {
        onSelect(isChecked, [resources[0]]);
        return;
      }

      const childResources = await mutateAsync({
        params: { resourceId: resources[0].ID }
      });
      onSelect(isChecked, [
        { ...resources[0], ChildResources: childResources }
      ]);
    },
    [onSelect, mutateAsync]
  );

  return {
    algoliaResultResources,
    isAlgoliaLoading,
    loadNextPage,
    totalCount,
    handleSelect
  };
};
