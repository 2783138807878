import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { client } from 'filestack-react';

interface SUploadTags {
  [key: string]: string;
}

export const useFilestack = () => {
  const { enqueueSnackbar } = useSnackbar();

  const fileStackUpload = useCallback(
    ({
      file,
      tags,
      getProgress
    }: {
      file: File;
      tags: SUploadTags;
      getProgress?: (total: number) => void;
    }) =>
      client
        .init(process.env.FILESTACK_KEY)
        .upload(
          file,
          {
            onProgress: async ({ totalPercent }: { totalPercent: number }) =>
              getProgress(totalPercent),
            tags
          },
          {
            location: 'azure'
          }
        )
        .then(res => res)
        .catch(() => {
          enqueueSnackbar(
            'There was an error uploading your file, please try again later',
            {
              variant: 'error'
            }
          );
        }),
    [enqueueSnackbar]
  );

  return {
    fileStackUpload
  };
};
