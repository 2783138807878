import { makeStyles, Theme } from '@material-ui/core';
import { useCallback } from 'react';
import SText, { STextProps } from '../sui/stext';

const useStyles = makeStyles<Theme, { highlightColor: string }>(
  ({ palette }) => ({
    highlight: {
      color: ({ highlightColor }) => highlightColor || palette.primary.main
    }
  })
);

interface SHighlightText extends STextProps {
  searchQuery: string;
  highlightColor?: string;
  value: string;
}

const SHighlightText = ({
  searchQuery,
  highlightColor,
  value,
  ...rest
}: SHighlightText) => {
  const classes = useStyles({ highlightColor });

  const markText = useCallback(() => {
    if (!value) return '-';

    const strArr = value.split(new RegExp(`(${searchQuery})`, 'ig'));
    return strArr.map((i, index) => {
      if (i === '') return null;
      if (i?.toLowerCase() === searchQuery?.toLowerCase()) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`match${index}`} className={classes.highlight}>
            {i}
          </span>
        );
      }
      return i;
    });
  }, [classes.highlight, searchQuery, value]);

  const markedText = markText();

  return (
    <SText title={value} {...rest}>
      {markedText}
    </SText>
  );
};

export default SHighlightText;
