import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, Theme, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { distances } from '@setvi/shared/styles';
import { SButton } from '@setvi/shared/components';
import { useSnackbar } from 'notistack';
import { Presentation } from '@setvi/shared/interfaces';
import { usePresentation } from 'Hooks/usePresentation';
import { ROUTES } from 'enumsV2';
import { DIALOG_TYPE } from '..';

const useStyles = makeStyles<Theme, { modify: boolean }>(theme => ({
  actionPaper: {
    boxShadow: 'none',
    paddingTop: distances.px.medium,
    display: 'flex',
    gap: 10,
    '& button': {
      height: 50,
      width: ({ modify }) => (modify ? '50%' : '100%')
    }
  },
  label: {
    color: '#44444f',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  confirmBtn: {
    textTransform: 'capitalize'
  },
  input: {
    marginTop: theme.spacing(2),
    caretColor: theme.palette.primary.main,
    '& fieldset': {
      borderRadius: 8,
      border: '1px solid transparent',
      background: 'rgba(123, 151, 206, 0.06)'
    }
  },
  required: {
    color: theme.palette.error.main
  }
}));

interface ContentProps {
  goTo: (path: string) => void;
  onClose?: () => void;
  onSuccess?: (presentation?: Presentation) => void;
  dialogType: DIALOG_TYPE;
  withResources?: string[];
  presentation?: Presentation;
}

export const Content = ({
  goTo,
  onClose,
  onSuccess,
  presentation,
  withResources,
  dialogType
}: ContentProps) => {
  const { create, modify, loading } = usePresentation();
  const { enqueueSnackbar } = useSnackbar();

  const modifyType = dialogType !== DIALOG_TYPE.CREATE;
  const classes = useStyles({ modify: modifyType });

  const info = {
    [DIALOG_TYPE.CREATE]: 'create',
    [DIALOG_TYPE.EDIT]: 'rename',
    [DIALOG_TYPE.DUPLICATE]: 'duplicate'
  };

  const submitPresentation = async (values: any) => {
    const { presentationName: name } = values;

    if (!name) return;
    let presentationID: number | string;

    if (dialogType === DIALOG_TYPE.EDIT) {
      const { Data } = await modify({
        body: {
          id: presentation.ID,
          name,
          presentationItems: presentation.PresentationItems.map(
            presentationItem => presentationItem.ItemID
          )
        }
      });

      presentationID = Data?.[0]?.ID;

      if (presentationID) {
        enqueueSnackbar(`The presentation have been renamed successfully`, {
          variant: 'success'
        });
        onSuccess?.(Data?.[0]);
        onClose();
      }
    } else {
      const { Data } = await create({
        body: {
          name,
          presentationItems: withResources ? [...withResources] : []
        }
      });

      presentationID = Data?.[0]?.ID;

      if (presentationID) {
        enqueueSnackbar(
          `The presentation have been ${info[dialogType]}d successfully`,
          {
            variant: 'success'
          }
        );
        onSuccess?.(Data?.[0]);
        onClose();
      }

      // Default create success behavior is to redirect to presentation content page
      goTo(`${ROUTES.WORKSPACES_PRESENTATIONS}/${presentationID}`);
    }
  };

  const presentationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      presentationName: presentation?.Name || ''
    },
    validationSchema: Yup.object({
      presentationName: Yup.string()
        .trim()
        .required('Presentation name is required')
        .max(100, 'Limit is 100 characters')
    }),
    onSubmit: submitPresentation
  });

  return (
    <>
      <form onSubmit={presentationForm.handleSubmit} noValidate>
        <Typography className={classes.label}>
          Presentation Name <span className={classes.required}>*</span>
        </Typography>
        <TextField
          className={classes.input}
          id="presentationName"
          name="presentationName"
          value={presentationForm.values.presentationName}
          onChange={presentationForm.handleChange}
          error={
            presentationForm.touched.presentationName &&
            Boolean(presentationForm.errors.presentationName)
          }
          helperText={
            <>
              {presentationForm.touched.presentationName &&
                presentationForm.errors.presentationName}
            </>
          }
          fullWidth
          type="text"
          variant="outlined"
        />

        <Paper component="div" className={classes.actionPaper}>
          {modifyType && (
            <SButton onClick={onClose} variant="outlined" color="inherit">
              Close
            </SButton>
          )}
          <SButton
            className={classes.confirmBtn}
            loading={loading}
            type="submit">
            {info[dialogType]}
          </SButton>
        </Paper>
      </form>
    </>
  );
};
