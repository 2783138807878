import { useCallback } from 'react';
import { Resource } from '@setvi/shared/interfaces';
import { Box } from '@material-ui/core';
import { useStyles } from '../../list/style';
import SInfiniteScroll from '../../../sinfinite-scroll';
import SemptyState from '../../../sempty-state';
import { Item } from '../item';
import SText from '../../../sui/stext';

interface ListProps {
  isStatic?: boolean;
  resources: Resource[];
  fetchMore: () => void;
  rowKeyGetter: (data: any) => number | string;
  selectedResources?: Resource[];
  onSelect: (checked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  loading: boolean;
  isEmpty: boolean;
  emptyStateInfo: {
    imageSrc: string;
    title: string;
    subTitle?: string;
  };
}

export const List = ({
  isStatic = false,
  resources,
  fetchMore,
  rowKeyGetter,
  loading,
  isEmpty,
  emptyStateInfo,
  selectedResources,
  onSelect,
  onSelectPages
}: ListProps) => {
  const classes = useStyles();

  const isChecked = useCallback(
    (resource: Resource) =>
      selectedResources?.some(i => rowKeyGetter(i) === rowKeyGetter(resource)),
    [selectedResources, rowKeyGetter]
  );

  return (
    <SInfiniteScroll
      containerClass={classes.container}
      list={resources}
      fetchMore={fetchMore}
      keyGetter={rowKeyGetter}
      render={(resource: any) => (
        <Item
          isStatic={isStatic}
          resource={resource}
          onSelect={onSelect}
          isChecked={isChecked(resource)}
          onSelectPages={onSelectPages}
        />
      )}>
      <>
        {loading && (
          <Box display="flex" justifyContent="center">
            <SText weight="medium">Loading...</SText>
          </Box>
        )}
        {!loading && isEmpty && (
          <SemptyState
            imageSrc={emptyStateInfo.imageSrc}
            title={emptyStateInfo.title}
            subTitle={emptyStateInfo.subTitle}
          />
        )}
      </>
    </SInfiniteScroll>
  );
};
