import { Box, Button, ButtonGroup } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import {
  SelectDetailsButton,
  SelectMenuHeader,
  SHighlightText,
  SSelectDropdown
} from '@setvi/shared/components';

import { BaseSelectProps, SOption } from '../types';
import { useSingleSelect } from './hook';

export interface SelectProps extends BaseSelectProps {
  onChange: (selected: SOption) => void;

  defaultOption?: SOption;
}

const SingleSelect = ({
  width,
  label = '',
  search = true,
  options = [],
  placeholder = '',
  headerTitle,
  clearButton = false,
  defaultOption,

  onChange
}: SelectProps) => {
  const {
    data,
    anchor,
    selected,
    searchQuery,

    setSearchQuery,
    setData,
    setAnchor,
    setSelected,
    handleSelect
  } = useSingleSelect({
    options,
    defaultOption,

    onChange
  });

  return (
    <SSelectDropdown
      width={width}
      placement="bottom"
      anchor={
        <SelectDetailsButton
          width={width}
          label={label}
          open={Boolean(anchor)}
          title={selected?.label || selected?.value?.toString() || placeholder}
          titleHighligted={false}
          onClick={e => setAnchor(e.currentTarget)}
        />
      }
      anchorElement={anchor}
      onClose={() => setAnchor(null)}
      content={
        <Box>
          <SelectMenuHeader
            search={search}
            title={headerTitle}
            clearButton={clearButton}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleClear={() => {
              setSelected(null);
              setData(options);
            }}
          />

          <ButtonGroup
            orientation="vertical"
            color="primary"
            style={{
              display: 'flex',
              overflow: 'auto',
              maxHeight: '400px'
            }}>
            {data?.length > 0 ? (
              data?.map(option => (
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  startIcon={
                    <CheckRoundedIcon
                      htmlColor={
                        selected?.value === option?.value ? '#2196F3' : '#fff'
                      }
                    />
                  }
                  key={option.id}
                  variant="text"
                  onClick={() => handleSelect(option)}
                  color="default"
                  disabled={option.disabled}>
                  <SHighlightText
                    searchQuery={searchQuery}
                    value={String(option?.value)}
                  />
                </Button>
              ))
            ) : (
              <>No Results Found...</>
            )}
          </ButtonGroup>
        </Box>
      }
    />
  );
};

export default SingleSelect;
