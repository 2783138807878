import { Box, makeStyles } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import moment from 'moment';
import { FC, useMemo } from 'react';

export const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    marginTop: spacing(3),
    marginBottom: spacing(3)
  }
}));

interface DateLabelProps {
  date: string;
}

export const DateLabel: FC<DateLabelProps> = ({ date }) => {
  const classes = useStyles();

  const momentDate = useMemo(() => moment(date, 'DD.MM.YYYY'), [date]);

  return (
    <Box className={classes.container}>
      <SText variant="body1" weight="bold">
        {momentDate.fromNow()} - {momentDate.format('MMM D, YYYY')}
      </SText>
    </Box>
  );
};
