import { makeStyles } from '@material-ui/core/styles';

export const usePaginationStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: '#44444F',
    marginTop: spacing(3)
  },
  perPageContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: `${spacing(2)}px`
    }
  },
  paginationRightSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 20
  },
  paggination: {
    margin: 0,
    '& ul > li > button': {
      color: '#44444F'
    },
    '& ul > li:first-child > button': {
      border: '1px solid #E2E2EA',
      borderRadius: '8px',
      fill: '#44444F'
    },
    '& ul > li:last-child > button': {
      border: '1px solid #E2E2EA',
      borderRadius: '8px',
      fill: '#44444F'
    },
    '& .Mui-selected': {
      color: '#0062FF',
      background: 'transparent',
      fontWeight: 700
    }
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px'
  }
}));
