import { FC } from 'react';

export interface IconProps {
  stroke: string;
  htmlColor: string;
  fontSize: string;
}

interface IconWrapperProps {
  Icon: FC<IconProps>;
  color: string;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  enableStroke?: boolean;
}

export const IconWrapper: FC<IconWrapperProps> = ({
  Icon,
  color,
  size = 'medium',
  enableStroke = false
}) => (
  <Icon stroke={enableStroke ? color : ''} htmlColor={color} fontSize={size} />
);
