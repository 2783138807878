import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { muiTheme } from '@setvi/shared/styles';
import DialogProvider from '@setvi/shared/providers/dialog';
import CustomSnackbarProvider from 'Providers/CustomSnackbar';
import { AppProvider } from 'Providers/AppProvider';
import { Favicon } from 'Components/Shared';
import { ReactQueryProvider } from 'Providers/ReactQuery';
import 'react-virtualized/styles.css'; // only needs to be imported once
import './App.css';
import { RecoilRoot } from 'recoil';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const App = () => (
  <ThemeProvider theme={muiTheme}>
    <Auth0Provider
      domain={
        window.location.origin.includes('middleby')
          ? process.env.AUTH0_DOMAIN_MIDDLEBY
          : process.env.AUTH0_DOMAIN
      }
      clientId={
        window.location.origin.includes('middleby')
          ? process.env.AUTH0_CLIENTID_MIDDLEBY
          : process.env.AUTH0_CLIENTID
      }
      authorizationParams={{
        redirect_uri: `${window.location.origin}/token-callback`,
        connection: window.location.origin.includes('middleby')
          ? process.env.AUTH0_REALM_MIDDLEBY
          : process.env.AUTH0_REALM,
        scope: 'openid name email'
      }}
      authorizeTimeoutInSeconds={300}>
      <CustomSnackbarProvider>
        <ReactQueryProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AppProvider>
              <RecoilRoot>
                <DialogProvider>
                  <Outlet />
                  <Favicon />
                  {process.env.NODE_ENV === 'development' ? (
                    <ReactQueryDevtools />
                  ) : null}
                </DialogProvider>
              </RecoilRoot>
            </AppProvider>
          </QueryParamProvider>
        </ReactQueryProvider>
      </CustomSnackbarProvider>
    </Auth0Provider>
  </ThemeProvider>
);

export default App;
